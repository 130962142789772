import httpService from "./httpService";

const ENDPOINT = "/DocumentOcr";
let cache = {};

const isPointInPolygon = (x, y, coordinates) => {
  let inside = false;
  for (let i = 0, j = coordinates.length - 1; i < coordinates.length; j = i++) {
    let xi = coordinates[i].X,
      yi = coordinates[i].Y;
    let xj = coordinates[j].X,
      yj = coordinates[j].Y;

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

const isPolygonIntersecting = (points1, points2) => {
  let a = points1;
  let b = points2;
  let polygons = [a, b];
  let minA, maxA, projected, minB, maxB, j;
  for (let i = 0; i < polygons.length; i++) {
    let polygon = polygons[i];
    for (let i1 = 0; i1 < polygon.length; i1 += 2) {
      let i2 = (i1 + 2) % polygon.length;
      let normal = { x: polygon[i2 + 1] - polygon[i1 + 1], y: polygon[i1] - polygon[i2] };
      minA = maxA = null;

      for (j = 0; j < a.length; j += 2) {
        projected = normal.x * a[j] + normal.y * a[j + 1];
        if (minA === null || projected < minA) {
          minA = projected;
        }
        if (maxA === null || projected > maxA) {
          maxA = projected;
        }
      }
      minB = maxB = null;
      for (j = 0; j < b.length; j += 2) {
        projected = normal.x * b[j] + normal.y * b[j + 1];
        if (minB === null || projected < minB) {
          minB = projected;
        }
        if (maxB === null || projected > maxB) {
          maxB = projected;
        }
      }
      if (maxA < minB || maxB < minA) {
        return false;
      }
    }
  }
  return true;
};

export default {
  loadPagesIntoCache: async (documentId, documentPages) => {
    for (let i = 0; i < documentPages.length; i++) {
      if (documentPages[i] !== 1) {
        let documentPageOcr = await httpService.get(ENDPOINT + "/" + documentId + "/page/" + documentPages[i]);

        if (!documentPageOcr) {
          continue;
        }

        let cacheKey = documentId + "-" + documentPageOcr.documentPage;

        documentPageOcr.ocrJson = JSON.parse(documentPageOcr.ocrJson);
        cache[cacheKey] = documentPageOcr;
      }
    }
  },

  load: async (documentId, documentPage) => {
    let cacheKey = documentId + "-" + documentPage;

    let documentPageOcr = await httpService.get(`${ENDPOINT}/${documentId}/page/${documentPage}`);

    if (!documentPageOcr) {
      return;
    }

    documentPageOcr.ocrJson = JSON.parse(documentPageOcr.ocrJson);
    cache[cacheKey] = documentPageOcr;
  },

  findText: (documentId, documentPage, relX, relY) => {
    let cacheKey = documentId + "-" + documentPage;
    let page = cache[cacheKey];

    let actualX = relX * page.width;
    let actualY = relY * page.height;

    let annotations = page.ocrJson;

    for (let i = 0; i < annotations.length; i++) {
      let annotation = annotations[i];

      if (isPointInPolygon(actualX, actualY, annotation.Coordinates)) {
        for (let j = 0; j < annotation.Coordinates.length; j++) {
          annotation.Coordinates[j].xRel = annotation.Coordinates[j].X / page.width;
          annotation.Coordinates[j].yRel = annotation.Coordinates[j].Y / page.height;
        }

        return annotation;
      }
    }

    return null;
  },

  findTextPoly: (documentId, documentPage, relX, relY, relWidth, relHeight) => {
    let result = [];

    let cacheKey = documentId + "-" + documentPage;
    let page = cache[cacheKey];

    let actualX = relX * page.width;
    let actualY = relY * page.height;
    let actualWidth = relWidth * page.width;
    let actualHeight = relHeight * page.height;

    let annotations = page.ocrJson;

    for (let i = 0; i < annotations.length; i++) {
      let annotation = annotations[i];

      let rectPoly = [
        actualX,
        actualY,
        actualX + actualWidth,
        actualY,
        actualX + actualWidth,
        actualY + actualHeight,
        actualX,
        actualY + actualHeight,
      ];

      let polyCoordinates = [];

      for (let j = 0; j < annotation.Coordinates.length; j++) {
        annotation.Coordinates[j].xRel = annotation.Coordinates[j].X / page.width;
        annotation.Coordinates[j].yRel = annotation.Coordinates[j].Y / page.height;

        polyCoordinates.push(annotation.Coordinates[j].X);
        polyCoordinates.push(annotation.Coordinates[j].Y);
      }

      if (isPolygonIntersecting(rectPoly, polyCoordinates)) {
        result.push(annotation);
      }
    }

    return result;
  },
};
