<template>
  <v-chip :color="value.color" class="pa-4" data-cy="route-status" outlined>
    <v-icon v-show="value.icon" left small>{{ value.icon }}</v-icon>
    {{ value.name }}
  </v-chip>
</template>

<script>
import documentRouteStatusList from "@/constants/documentRouteStatusList";
const defaultValue = documentRouteStatusList[0];

export default {
  props: {
    id: Number,
  },

  computed: {
    value() {
      return this.id ? documentRouteStatusList.find(x => x.id === this.id) : defaultValue;
    },
  },
};
</script>
