<template>
  <div>
    <PaymentDialog ref="paymentDialog" :documentId="documentId" :addPaymentHandler="addPayment"> </PaymentDialog>
    <div class="mt-5">
      <div v-for="payment in payments" :key="payment.id">
        <v-card class="mt-3">
          <v-card-text>
            <v-row>
              <v-col>
                <v-badge color="green" :content="` Paid: $${payment.amount} `" />
              </v-col>
              <v-col class="text-right new-flag">{{ payment.transactionDate | moment(dateFormat.toUpperCase()) }}</v-col>
            </v-row>
            <v-row dense>
              <v-col><b>ID:</b> {{ payment.paymentId }}</v-col>
            </v-row>
            <v-row dense>
              <v-col><b>Check #:</b> {{ payment.checkNumber }}</v-col>
            </v-row>
            <v-row dense>
              {{ payment.description }}
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentDialog from "@/dialogs/PaymentDialog";
import PaymentService from "@/services/paymentService";
import { mapState } from "vuex";

export default {
  name: "Payments",
  components: {
    PaymentDialog,
  },
  props: {
    documentId: Number,
  },
  data() {
    return {
      showDialog: false,
      payments: [],
    };
  },
  methods: {
    async addPayment(data) {
      await PaymentService.addPayment(data);
      this.payments.push(data);
      this.$root.$snackbar.message("The payment has been added");
    },
  },
  async mounted() {
    this.payments = (await PaymentService.loadPayments(this.documentId)).items;
  },
  computed: {
    ...mapState("userProfile", ["dateFormat"]),
  },
};
</script>
<style scoped>
.badge-money {
  margin-top: 15px;
  height: 30px;
  padding: 10px;
}
</style>