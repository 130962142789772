export default {
  AR_INVOICE: 93,
  W9: 6,
  ACH: 86,
  DIVISION_ORDER: 87,
  RUN_STATEMENT: 89,
  DOCUMENT_1099: 90,
  OWNER_STATEMENT: 91,
  PENDING_DIVISION_ORDER: 94,
};
