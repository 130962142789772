import httpService from "./httpService";

export default {
  getBatchDocumentIds: async (batchName, userId) =>
    httpService.get(`/batch/getdocumentids?batchName=${encodeURIComponent(batchName)}&userId=${userId}`),

  generateBatchName: currentDate => {
    let currentdate = currentDate || new Date();

    let datetime =
      (currentdate.getMonth() + 1 < 10 ? "0" : "") +
      (currentdate.getMonth() + 1) +
      "/" +
      (currentdate.getDate() < 10 ? "0" : "") +
      currentdate.getDate() +
      "/" +
      currentdate.getFullYear() +
      " " +
      (currentdate.getHours() < 10 ? "0" : "") +
      currentdate.getHours() +
      ":" +
      (currentdate.getMinutes() < 10 ? "0" : "") +
      currentdate.getMinutes() +
      ":" +
      (currentdate.getSeconds() < 10 ? "0" : "") +
      currentdate.getSeconds() +
      "." +
      currentdate.getMilliseconds();

    return `Uploaded-[${datetime}]`;
  },
};
