import invitationService from "../services/invitationService";

export default {
    namespaced: true,

    state: {
        pendingInvitations: 0,
    },

    mutations: {
        setPendingInvitations: async (state) => {
            let pendingInvitations = await invitationService.getPending();
            state.pendingInvitations = pendingInvitations.length;
        },
        removePendingInvitation: state => {
            --state.pendingInvitations;
        },
    },
    getters: {
        pendingInvitations: state => state.pendingInvitations,
    },
    actions: {
        setPendingInvitations({ commit }) {
            commit("setPendingInvitations");
        },
        removePendingInvitation({ commit }) {
            commit("removePendingInvitation");
        },
    },
};
