<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-right="calendarPopupNudgeRight"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @blur="onBlur"
        :flat="isLineItem"
        :solo="isLineItem"
        :hide-details="isLineItem ? 'auto' : ''"
        ref="textField"
        v-bind:class="{ 'pt-4': !isLineItem }"
        v-mask="dateMask"
        placeholder="MM/dd/yyyy"
        :disabled="documentMetadata.metadata.isReadonly"
        v-model="formattedDate"
        :label="label"
        :append-icon="highlight && documentMetadata.autoExtracted ? 'mdi-auto-fix' : 'mdi-calendar'"
        v-bind="attrs"
        v-on="on"
        :background-color="highlight ? HIGHLIGHT_BACKGROUND : 'transparent'"
        :data-cy="documentMetadata.metadata.key"
      ></v-text-field>
    </template>
    <v-date-picker v-model="documentMetadata.value" @input="showMenu = false"></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dateService from "@/services/dateService";
import documentMetadataService from "@/services/documentMetadataService";
import colors from "@/constants/colors";
import responsiveService from "@/services/responsiveService";

export default {
  name: "DateMetadata",
  props: {
    documentMetadata: Object,
    textSelection: Object,
    isLineItem: Boolean,
    focusOnLoad: Boolean,
    onClickToFill: Function,
  },
  data() {
    return {
      showMenu: false,
      highlight: false,
      HIGHLIGHT_BACKGROUND: colors.HIGHLIGHT_BACKGROUND,
      manualDateEntry: null,
    };
  },
  watch: {
    textSelection() {
      // has focus?
      if (this.$refs.textField.$el.querySelectorAll("input")[0] !== document.activeElement) return;

      this.documentMetadata.value = moment(this.textSelection.text).format("YYYY-MM-DD");

      documentMetadataService.assignTextSelection(this);
      this.manualDateEntry = this.documentMetadata.value;

      if (this.onClickToFill) this.onClickToFill();
    },

    focusOnLoad() {
      documentMetadataService.focusIfRequested(this);
    },
  },

  created() {
    this.documentMetadata.value = dateService.removeTimePart(this.documentMetadata.value);

    if (this.documentMetadata.highlight) {
      this.highlight = true;
      setTimeout(() => {
        this.highlight = false;
        this.documentMetadata.autoExtracted = false;
      }, 2000);
    }

    documentMetadataService.focusIfRequested(this);
  },

  methods: {
    onBlur() {
      this.documentMetadata.value = this.manualDateEntry;
    },
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),

    label() {
      return this.isLineItem ? "" : this.documentMetadata.metadata.name;
    },

    dateMask() {
      return dateService.toMask(this.dateFormat);
    },

    calendarPopupNudgeRight() {
      return responsiveService.isMobile(this.$vuetify.breakpoint) ? -70 : 0;
    },

    formattedDate: {
      get: function() {
        if (!this.documentMetadata.value || !this.dateFormat) return "";

        return moment(this.documentMetadata.value).format(this.dateFormat.toUpperCase());
      },
      set: function(newValue) {
        let date = moment(newValue, this.dateFormat.toUpperCase(), true);

        if (date.isValid()) {
          this.manualDateEntry = date.format("YYYY-MM-DD");
        } else {
          this.manualDateEntry = null;
        }
      },
    },
  },
};
</script>
