import documentMetadataService from "@/services/documentMetadataService.js";
import metadataType from "../constants/metadataType";
import moment from 'moment';

export default {
  translateResultSet: (resultSet, dateFormat) => {
    const headers = resultSet.columns.map(column => {
      return { 
        text: column.name, 
        value: column.key, 
        metadataTypeId: column.metadataType.id,
        sort: (a, b) => {
          if (column.metadataType.id === metadataType.Date) {
            return moment(b, dateFormat).toDate() - moment(a, dateFormat).toDate();
          } else {
            return b.toString().localeCompare(a.toString());
          }
        } 
      };
    });
    
    const rows = resultSet.rows.map(item => {
      let document = { id: item.id, routeStatusId: item.routeStatusId, isRead: item.isRead };
      headers.forEach((column, index) => {
        document[column.value] = documentMetadataService.format(column.metadataTypeId, item.values[index], dateFormat);
      });

      return document;
    });

    headers.push({ text: "Status", value: "routeStatusId", metadataTypeId: metadataType.Numeric });

    return {
      headers,
      rows,
    };
  },
};
