export default {
  namespaced: true,

  state: {
    logoData: undefined,
  },

  mutations: {
    updateLogo: (state, logoData) => {
      state.logoData = logoData;
    },
  },
  getters: {
    logoData: state => state.logoData,
  },
  actions: {
    initLogo({ commit }, logoData) {
      commit("updateLogo", logoData);
    },
  },
};
