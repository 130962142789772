import notificationService from "./notificationService";
import logException from "./exceptionLoggingService";
import store from "../store";

function extractNotificationUrl(companyId, companies) {
  if (!companies) return "";
  let company = companies.find(x => x.id === companyId);
  if (!company) return "";
  var pos = company.url.indexOf("/?selectedCompanyId");
  pos = pos > 0 ? pos : company.url.length;
  let url = `${company.url.substring(0, pos)}/notifications${company.url.substring(pos)}`;
  return url;
}

function clickHandler(event, companyId, companies) {
  event.preventDefault();
  let url = extractNotificationUrl(companyId, companies);
  if (url != "") window.open(url, "_blank");
}

export default {
  isNotificationGranted: null,
  fallbackFunction: null,
  selectedCompanyId: null,
  async isNotificationApiEnabled() {
    if (this.isNotificationGranted !== null) return this.isNotificationGranted;
    if (!("Notification" in window)) return false;
    if (Notification.permission === "granted") return true;
    if (Notification.permission === "denied") return false;
    var permission = await Notification.requestPermission();
    this.isNotificationGranted = permission === "granted";
    return this.isNotificationGranted;
  },
  async showNotification(messageJson) {
    var useApi = await this.isNotificationApiEnabled();
    if (useApi) {
      var not = new Notification(messageJson.Message);
      not.addEventListener("click", event => clickHandler(event, messageJson.SourceCompanyId, this.companies));
    } else {
      this.fallbackFunction(messageJson.Message, extractNotificationUrl(messageJson.SourceCompanyId, this.companies));
    }
  },
  setCompanyId(companyId) {
    this.selectedCompanyId = companyId;
  },
  async initialize(companies, fallbackFunction, selectedCompanyId) {
    this.companies = companies;
    this.fallbackFunction = fallbackFunction;
    this.selectedCompanyId = selectedCompanyId;
    var uri = await notificationService.getSubscribeUri();
    this.ws = new WebSocket(uri, "json.webpubsub.azure.v1");

    this.ws.onerror = evt => {
      console.log(`WebSocket error ${evt.message}`);
      logException.logException(evt, "Websocket error", "App.vue");
    };
    this.ws.onclose = evt => {
      console.log(`WebSocket closed, code: ${evt.code}`);
    };

    this.ws.onmessage = msg => {
      const eventData = JSON.parse(msg.data);
      if (eventData.type === "message") {
        const message =
          eventData.dataType === "json" ? eventData.data : { Message: eventData.message, Title: "notification" };
        this.showNotification(message);
        if (eventData.data.SourceCompanyId === this.selectedCompanyId)
          store.dispatch("userProfile/addUnreadNotification");
        if (eventData.data.Title == 'Received invitation')
          store.dispatch("invitationStore/setPendingInvitations");
      }
    };
  },
};
