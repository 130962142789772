<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-right="calendarPopupNudgeRight"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex datetime-control" dense>
        <v-text-field
          class="calendarpart"
          v-on="on"
          v-bind="attrs"
          :background-color="highlight ? HIGHLIGHT_BACKGROUND : 'transparent'"
          :flat="isLineItem"
          :solo="isLineItem"
          :class="{ 'pt-1': isLineItem }"
          :hide-details="isLineItem ? 'auto' : ''"
          @blur="onBlur"
          ref="textField"
          v-mask="dateMask"
          :disabled="documentMetadata.metadata.isReadonly"
          v-model="formattedDate"
          :label="label"
          :append-icon="highlight && documentMetadata.autoExtracted ? 'mdi-auto-fix' : 'mdi-calendar'"
          :data-cy="documentMetadata.metadata.key"
        ></v-text-field>
        <v-text-field class="centered-input ml-2" v-mask="timeMask" label="hh:mm" v-model="timePart"></v-text-field>
        <v-select :items="amPmValues" v-model="amPmPart" class="centered-select ml-2"></v-select>
      </div>
    </template>
    <v-date-picker v-model="datePart" @input="showMenu = false"></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dateService from "@/services/dateService";
import documentMetadataService from "@/services/documentMetadataService";
import colors from "@/constants/colors";
import responsiveService from "@/services/responsiveService";

export default {
  name: "DateTimeMetadata",
  props: {
    documentMetadata: Object,
    textSelection: Object,
    isLineItem: Boolean,
    focusOnLoad: Boolean,
    onClickToFill: Function,
  },
  data() {
    return {
      showMenu: false,
      highlight: false,
      HIGHLIGHT_BACKGROUND: colors.HIGHLIGHT_BACKGROUND,
      manualDateEntry: null,
      amPmValues: ["AM", "PM"],
      amPmPart: "AM",
      timePart: null,
      datePart: null,
    };
  },
  watch: {
    textSelection() {
      // has focus?
      if (this.$refs.textField.$el.querySelectorAll("input")[0] !== document.activeElement) return;

      this.documentMetadata.value = moment(this.textSelection.text).format("YYYY-MM-DD");
      documentMetadataService.assignTextSelection(this);
      this.manualDateEntry = this.documentMetadata.value;

      if (this.onClickToFill) this.onClickToFill();
    },

    amPmPart() {
      this.documentMetadata.value = this.fullValue;
    },
    timePart() {
      this.documentMetadata.value = this.fullValue;
    },
    datePart() {
      this.documentMetadata.value = this.fullValue;
    },
    focusOnLoad() {
      documentMetadataService.focusIfRequested(this);
    },
  },

  created() {
    if (this.documentMetadata.value) {
      let parsedDate = dateService.parseDateTime(this.documentMetadata.value);
      this.datePart = parsedDate.datePart;
      let hours = +parsedDate.timePart.split(":")[0];
      let hours12 = hours > 12 ? hours - 12 : hours;
      this.timePart = `${hours12}:${parsedDate.timePart.split(":")[1]}`;
      this.amPmPart = hours > 12 ? "PM" : "AM";
    }
    if (this.documentMetadata.highlight) {
      this.highlight = true;
      setTimeout(() => {
        this.highlight = false;
        this.documentMetadata.autoExtracted = false;
      }, 2000);
    }

    documentMetadataService.focusIfRequested(this);
  },

  methods: {
    onBlur() {
      this.datePart = this.manualDateEntry;
    },
    timeMask(input) {
      let defaultMask = [/\d/, /[0-2]/, ":", /[0-5]/, /\d/];
      if (!isNaN(input.split(":")[0])) {
        let hours = Number(input.split(":")[0]);
        if (hours > 1 && hours < 10) defaultMask = ["0", /\d/, ":", /[0-5]/, /\d/];
        if (hours === 1) defaultMask = ["1", /[0-2]/, ":", /[0-5]/, /\d/];
      }
      return defaultMask;
    },
  },
  computed: {
    ...mapState("userProfile", ["dateFormat"]),

    label() {
      return this.isLineItem ? "" : this.documentMetadata.metadata.name;
    },

    dateMask() {
      return dateService.toMask(this.dateFormat);
    },
    fullValue() {
      if (!this.datePart) return null;
      let time = '00:00';
      if (this.timePart?.length > 4) {
        time = this.timePart;
        if (this.amPmPart === "PM"){
          let hours = +this.timePart.slice(0, 2) + 12;
          let minutes = this.timePart.slice(3, 5);
          time = hours + ':' + minutes;          
        }
      }
      return this.datePart + 'T' + time + ':00';
    },
    calendarPopupNudgeRight() {
      return responsiveService.isMobile(this.$vuetify.breakpoint) ? -70 : 0;
    },

    formattedDate: {
      get: function() {
        if (!this.datePart || !this.dateFormat) return "";
        return moment(this.datePart).format(this.dateFormat.toUpperCase());
      },
      set: function(newValue) {
        let date = moment(newValue, this.dateFormat.toUpperCase(), true);
        if (date.isValid()) {
          this.manualDateEntry = date.format("YYYY-MM-DD");
        } else {
          this.manualDateEntry = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.calendarpart {
  width: 200px;
}
.centered-input {
  width: 45px;
}
.datetime-control {
  margin-bottom: -13px;
}
.centered-select {
  width: 58px;
}

.centered-input >>> input {
  text-align: center;
}
.centered-input >>> label {
  text-align: center;
  width: 100%;
}

</style>
