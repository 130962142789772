<template>
  <div>
    <div v-for="field in fields" :key="field.attribute">
      <!-- Text -->
      <v-text-field
        v-if="field.type === 'text'"
        :label="field.label"
        v-model="entity[field.attribute]"
        :rules="field.rules"
        counter
        :hint="field.hint"
        :maxlength="field.maxlength || 255"
        :readonly="field.readonly"
        :filled="field.readonly"
        :disabled="field.disabled"
      >
        <template v-slot:prepend>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" v-show="field.altText">mdi-information</v-icon>
            </template>
            {{ field.altText }}
          </v-tooltip>
        </template>
      </v-text-field>

      <!-- Date -->
      <v-menu
        v-if="field.type === 'date'"
        v-model="field.showDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-if="field.type === 'date'"
            :value="entity[field.attribute] | moment(dateFormat)"
            :label="field.label"
            persistent-hint
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="entity[field.attribute]" no-title @input="field.showDatePicker = false"></v-date-picker>
      </v-menu>

      <!-- Select -->
      <v-select
        v-if="field.type === 'select'"
        :items="field.items"
        v-model="entity[field.attribute]"
        item-text="name"
        item-value="id"
        :readonly="field.readonly"
        :label="field.label"
        :rules="field.rules"
        :disabled="field.disabled"
      ></v-select>

      <!-- Multi Select -->
      <v-select
        v-if="field.type === 'multi-select'"
        v-model="entity[field.attribute]"
        :items="field.items"
        :label="field.label"
        item-text="name"
        item-value="id"
        multiple
        chips
        persistent-hint
      ></v-select>

      <!-- Checkbox -->
      <v-checkbox
        v-if="field.type === 'checkbox'"
        :label="field.label"
        v-model="entity[field.attribute]"
        @click="onClick(field.click)()"
      >
      </v-checkbox>

      <!-- Radio Group -->
      <v-radio-group
        v-if="field.type === 'radio'"
        :label="field.label"
        :rules="field.rules"
        v-model="entity[field.attribute]"
      >
        <v-radio v-for="item in field.items" :key="item.id" :label="item.name" :value="item.id" />
      </v-radio-group>

      <!-- upload file -->
      <v-file-input
        v-if="field.type === 'uploadFile'"
        :ref="field.ref"
        :accept="field.accept"
        :placeholder="field.hint"
        :prepend-icon="field.icon"
        v-model="entity[field.attribute]"
        :disabled="field.disabled"
        filled
        dense
      />
      <!-- Header -->
      <h3 class="headline mt-4 pt-4 mb-4 pb-4" v-if="field.type === 'header'">
        {{ field.label }}
      </h3>
    </div>

    <slot></slot>

    <div>
      <!-- Checkbox -->
      <v-checkbox
        v-if="isActiveField"
        :label="isActiveField.label"
        v-model="entity[isActiveField.attribute]"
        :disabled="isActiveField.disabled"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntityForm",
  props: {
    entity: Object,
    inputFields: Array,
  },
  methods: {
    onClick(handler) {
      const empty = () => {};
      return handler || empty;
    },
  },
  computed: {
    fields: function() {
      return this.inputFields.filter(f => f.attribute !== "isActive");
    },
    dateFormat() {
      return this.$store.getters["userProfile/dateFormat"];
    },
    isActiveField: function() {
      const field = this.inputFields.find(f => f.attribute === "isActive");
      return field;
    },
  },
};
</script>
