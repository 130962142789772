<template>
  <div class="org-select">
    <div class="metadata-label">{{ documentMetadata.metadata.name }}</div>
    <vselect
      @search="onSearch"
      ref="textField"
      placeholder="Type to search by name or code..."
      v-model="documentMetadata.value"
      :disabled="documentMetadata.metadata.isReadonly"
      :options="parties"
      label="name"
      :reduce="p => (p ? p.id : -1)"
      :data-cy="documentMetadata.metadata.key"
    >
      <template #option="{ name, number, addressLineOne, city, state }">
        <div class="pa-1">
          <b>{{ name }}</b>
          <div class="party-option-details">{{ number }} {{ formatAddress({ addressLineOne, city, state }) }}</div>
        </div>
      </template>
      <template #no-options="{}"> Sorry, no matching {{ documentMetadata.metadata.name }} </template>
    </vselect>
    <div v-show="party" class="party-address">{{ party ? party.number : "" }} {{ formatAddress(party) }}</div>
  </div>
</template>

<script>
import partyService from "@/services/partyService";
import partyConstants from "@/constants/partyStatus";
import filterService from "@/services/filterService.js";
import _ from "lodash";

export default {
  name: "PartyMetadata",
  props: {
    documentMetadata: Object,
    partyTypeId: Number,
    textSelection: Object,
    focusOnLoad: Boolean,
    rules: Array,
    onClickToFill: Function,
  },

  data() {
    return {
      parties: [],
      party: null,
      loading: false,
      searchQuery: "",
      formatAddress: partyService.formatAddress,
      highlight: filterService.highlight,
    };
  },

  watch: {
    "documentMetadata.value": async function() {
      this.party = this.parties.find(p => p && p.id === this.documentMetadata.value);
      if (!this.documentMetadata.value) {
        this.parties = (await this.getInitialPartyList()).items;
      }
    },

    focusOnLoad: function() {
      this.requestFocus();
    },
  },

  methods: {
    requestFocus() {
      if (!this.focusOnLoad) return;

      // Already has a value - skip to the next
      if (this.documentMetadata.value) {
        return this.onClickToFill && this.onClickToFill();
      }
      
      setTimeout(() => {
        this.$refs.textField.$refs.search.focus();
      }, 300);
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      partyService
        .search(true, search, vm.partyTypeId, "", {
          sortDesc: [false],
          page: 1,
          itemsPerPage: 100,
          sortBy: "name",
        })
        .then(res => {
          loading(false);
          vm.parties = res.items;
        });
    }, 150),

    async getInitialPartyList() {
      return await partyService.search(true, "", this.partyTypeId, partyConstants.CONNECTED, {
        sortDesc: [false],
        page: 1,
        itemsPerPage: 100,
        sortBy: "name",
      }, 
          true);
    },
  },

  async created() {
    let partyResult = await this.getInitialPartyList();
    if (this.documentMetadata.value) {
      this.parties = [this.party];
      this.documentMetadata.value = parseInt(this.documentMetadata.value);

      this.party = await partyService.get(this.documentMetadata.value);
      this.parties = [this.party, ...partyResult.items.filter(i => i.id !== this.party.id)];
    } else {
      this.parties = partyResult.items;
      if (this.parties.length === 1) {
        this.documentMetadata.value = this.parties[0].id;
        this.party = this.parties[0];
      }
    }

    this.requestFocus();
  },
};
</script>

<style>
.party-address {
  font-size: 12px;
  padding: 2px 10px;
}
</style>
