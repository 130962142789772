import httpService from "./httpService";
import documentMetadataService from "@/services/documentMetadataService";
import dateService from "@/services/dateService";
import metadataType from "@/constants/metadataType";
import _ from "lodash";

const ICON_LOOKUP = {
  Update: "pencil",
  Create: "file-plus",
  "Update Coding": "pencil",
  View: "eye",
  Upload: "upload",
  Stamp: "seal",
  Comment: "comment-outline",
  "Delete Comment": "delete",
  "Delete Stamp": "delete",
  Download: "download",
  Email: "email",
  "Export History": "export",
  "Export Metadata": "export",
  "Check Out": "lock",
  "Revert Check Out": "lock-open",
  "Check In": "lock-open",
  ChangedFolders: "folder-move",
  "Reject To System": "close",
  "Reject from Approval": "close",
  Reject: "close",
  Approve: "check",
  "Update Approval Groups": "sitemap",
  "Notification Disabled": "bell-off",
  "Notification Sent": "bell",
  "Payment Added": "currency-usd",
  "Payment Cancelled": "currency-usd-off",
  "Sent to External System": "send",
  "Accepted by External System": "check",
  "Rejected by External System": "close",
  "Move to Folder": "folder",
  "Export to XML": "xml",
  "Upload Attachment": "attachment",
  "Download Attachment": "download",
  "View Attachment": "eye",
  "Delete Attachment": "delete",
  "eForm Submission": "clipboard-outline",
  "Skip Workflow": "debug-step-over",
  "View Payment": "currency-usd",
  "Shadow Copy": "content-copy",
  "Restart Workflow": "restart",
  "Add Document Relationship": "content-copy",
  "Remove Document Relationship": "close",
  "Sign Document": "signature",
  "Resubmit Document": "refresh",
  "Upload Replacement File": "upload",
};

const THIRD_PARTY_ID_SUFFIX = "-third-party";

export default {
  getHistoryForDocument: async id => httpService.get(`/history/document/${id}`),
  getRejectionReason: async id => httpService.get(`/history/document/${id}/rejectionReason`),

  getHistoryDetailsForDocument: async (id, historyId) => httpService.get(`/history/${id}/details/${historyId}`),

  formatHistoryOperation: history => {
    let n = history.operation.name;

    if (n === "Delete") return "Deleted";
    if (n === "Create") return "New version created";
    if (n === "Delete Stamp") return "Stamp deleted";
    if (n === "Delete Comment") return "Comment deleted";
    if (n === "Update") return "Updated";
    if (n === "Update Coding") return "Updated Coding";
    if (n === "Comment") return "Comment";
    if (n === "Export History") return "Exported History";
    if (n === "Export Metadata") return "Exported Metadata";
    if (n === "Check Out") return "Checked Out";
    if (n === "Revert Check Out") return "Reverted Check Out";
    if (n === "Check In") return "Checked In";
    if (n === "ChangedFolders") return "Changed Folder";
    if (n === "Reject To System") return "Rejected to System";
    if (n === "Reject from Approval") return "Rejected from Approval";
    if (n === "Reject") return "Rejected";
    if (n === "Approve") return "Approved";
    if (n === "Update Approval Groups") return "Updated Approval Groups";
    if (n === "Notification Disabled") return n;
    if (n === "Notification Sent") return n;
    if (n === "Payment Added") return n;
    if (n === "Payment Cancelled") return n;
    if (n === "Sent to External System") return n;
    if (n === "Accepted by External System") return n;
    if (n === "Rejected by External System") return "Rejected by External Party";
    if (n === "Move to Folder") return n;
    if (n === "Export to XML") return "Exported to XML";
    if (n === "Upload Attachment") return "Attachment Uploaded";
    if (n === "Download Attachment") return "Downloaded Attachment";
    if (n === "View Attachment") return "Viewed Attachment";
    if (n === "Delete Attachment") return "Deleted Attachment";
    if (n === "eForm Submission") return "eForm Submitted";
    if (n === "Skip Workflow") return "Skipped Workflow";
    if (n === "View Payment") return "Viewed Payment";
    if (n === "Shadow Copy") return "Shadow Copied";
    if (n === "Restart Workflow") return "Restarted the Workflow";
    if (n === "Add Document Relationship") return "Added Related Document";
    if (n === "Remove Document Relationship") return "Removed Related Document";
    if (n === "Sign Document") return "Signed Document";
    if (n === "Resubmit Document") return "Resubmitted";
    if (n === 'Sent to External Party') return n;
    if (n === 'Upload Replacement File') return "Replacement File Uploaded";

    return n + "ed";
  },

  getIcon: history => {
    return ICON_LOOKUP[history.operation.name];
  },

  extractHeaderHistory(historyDetails, documentMetadata, dateFormat) {
    const addExplicitBlank = (field, value) => {
      if (!value) {
        return "[BLANK]";
      }

      if (field && field.metadata.metadataType.id === metadataType.Date) {
        if (!field.metadata.isMultiple) {
          return dateService.formatDate(value, dateFormat);
        } else {
          return value
            .split(",")
            .map(function(item) {
              return dateService.formatDate(item, dateFormat);
            })
            .join(",");
        }
      } else if (field && field.metadata.metadataType.id === metadataType.DateTime) {
        if (!field.metadata.isMultiple) {
          return dateService.formatDateTime(value, dateFormat);
        } else {
          return value
            .split(",")
            .map(function(item) {
              return dateService.formatDateTime(item, dateFormat);
            })
            .join(",");
        }
      }

      return value;
    };

    let metadataHeaderHistory = historyDetails.filter(d => {
      let m = documentMetadata.find(
        m => m.metadata.id === d.objectId && !m.metadata.isThirdParty && d.objectType.name === "Metadata"
      );
      return d.rowNumber === 0 && m && ((d.oldValue !== d.newValue && !m.metadata.isMultiple) || m.metadata.isMultiple);
    });
    let thirdPartyMetadataHeaderHistory = historyDetails.filter(d => {
      let m = documentMetadata.find(
        m => m.metadata.id === d.objectId && m.metadata.isThirdParty && d.objectType.name === "ThirdPartyMetadata"
      );
      return d.rowNumber === 0 && m && ((d.oldValue !== d.newValue && !m.metadata.isMultiple) || m.metadata.isMultiple);
    });

    // Later we group by objectId. So to not conflict between two equal ids for ThirdParty and normal, we do this trick
    thirdPartyMetadataHeaderHistory.forEach(x => {
      x.objectId += THIRD_PARTY_ID_SUFFIX;
    });
    let headerHistory = [...metadataHeaderHistory, ...thirdPartyMetadataHeaderHistory];

    let allMetadata = documentMetadata.concat([
      {
        id: 4,
        name: "Document Type",
        metadataType: {
          name: "Document Type",
        },
      },
    ]);

    let groups = _.groupBy(headerHistory, "objectId");
    let values = Object.keys(groups).map(function(e) {
      return groups[e];
    });
    return _.map(values, function(d) {
      var onlyNonEmpty = function(e, attr) {
        return _.map(
          _.filter(e, function(f) {
            return _.isString(f[attr]) && f[attr].length > 0;
          }),
          attr
        );
      };

      var record = {
        name: d[0]["objectName"],
        oldValue: d[0]["oldValue"],
        newValue: onlyNonEmpty(d, "newValue").join(),
        objectId: d[0].objectId,
      };
      var m = _.find(allMetadata, function(m) {
        return (
          (m.metadata && m.metadata.id === record.objectId) ||
          `${m.thirdPartyMetadataId}${THIRD_PARTY_ID_SUFFIX}` === record.objectId
        );
      });
      return {
        field: record.name ? record.name : "field",
        before: addExplicitBlank(m, record.oldValue),
        after: addExplicitBlank(m, record.newValue),
        isEqual: record.newValue === record.oldValue || (!record.newValue && !record.oldValue),
      };
    });
  },

  extractLineItemsHistory(historyDetails, dateFormat, lineItemMetadatas) {
    let lineItems = [];
    let lineItemHeaders = [];

    const pushColumnHeader = f => {
      var columnIndex = _.findIndex(lineItemHeaders, function(c) {
        return c.value === f.objectName;
      });
      if (columnIndex === -1) {
        lineItemHeaders.push({ text: f.objectName, value: f.objectName });
        columnIndex = lineItemHeaders.length - 1;
      }
    };

    let onlyLineItemDetails = _.filter(historyDetails, function(d) {
      return d.rowNumber > 0;
    });
    _.each(onlyLineItemDetails, function(f) {
      pushColumnHeader(f);
      var metadata = lineItemMetadatas.find(x => x.id === f.objectId);

      var change = {
        newValue: documentMetadataService.format(metadata.metadataType.id, f.newValue, dateFormat),
        oldValue: documentMetadataService.format(metadata.metadataType.id, f.oldValue, dateFormat),
        isEqual: f.newValue === f.oldValue || (!f.newValue && !f.oldValue),
      };

      const index = f.rowNumber - 1;

      if (!lineItems[index]) {
        let emptyRow = {};
        emptyRow[f.objectName] = change;

        lineItems[index] = emptyRow;
      } else {
        lineItems[index][f.objectName] = change;
      }
    });

    return { lineItems, lineItemHeaders };
  },

  extractCommentHistory(historyDetails) {
    var nonMetadataChange = historyDetails.length === 1 && historyDetails[0].metadataTypeId === -1;

    // Not a metadata change
    if (nonMetadataChange && historyDetails[0].operation.name === "Update Approval Groups") {
      return (
        '<b>Approval groups</b> was changed from <i class="history-before">\' ' +
        historyDetails[0].oldValue +
        "' </i> to <i class=\"history-after\">'" +
        historyDetails[0].newValue +
        "'</i>"
      );
    }

    if (nonMetadataChange) {
      let comment = historyDetails[0].newValue;

      if (historyDetails[0].oldValue) {
        var operationName = historyDetails[0].operation.name === "Shadow Copy" ? "Copied from" : "Changed from";
        comment += " (" + operationName + " " + historyDetails[0].oldValue + ")";
      }

      return comment;
    }
  },
};
