<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }">
    <v-form ref="form">
      <v-card>
        <v-card-text class="title pt-4" v-show="!!message">{{ message }}</v-card-text>
        <v-card-text>
          <v-text-field
            v-on:keydown.enter.prevent="ok"
            :rules="rules"
            v-model="input"
            block
            :label="inputLabel"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="input.length === 0" text @click.native="ok">OK</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    input: "",
    inputLabel: null,
    rules: [],
    options: {
      color: "red",
      width: 390,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, inputLabel, rules, preAssignedValue) {
      this.dialog = true;
      this.message = message;
      this.inputLabel = inputLabel;
      this.rules = rules;
      this.input = preAssignedValue || "";

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.resolve(this.input);
      this.dialog = false;
    },
    cancel() {
      this.resolve(null);
      this.dialog = false;
    },
  },
};
</script>
