import moment from "moment";

export default {
  formatDate: (date, dateFormat) => moment(date).format(dateFormat.toUpperCase()),

  formatDateTime: (date, dateFormat) => moment(date).format(dateFormat.toUpperCase() + " hh:mm:ss A"),

  toMask: dateFormat =>
    dateFormat
      ? dateFormat
          .toUpperCase()
          .replace("YYYY", "####")
          .replace("MM", "##")
          .replace("DD", "##")
      : "",

  removeTimePart: input => {
    // Removes the time part from these kinds of dates
    // 2021-08-24T14:39:49.84
    if (input && input.length > 15 && input[10] === "T") {
      return input.substring(0, input.indexOf("T"));
    }

    return input;
  },
  parseDateTime: input => {
    var localDateTime = moment(input).local();
    var datePart = localDateTime.format("YYYY-MM-DD");
    var timePart = localDateTime.format("HH:mm:ss");
    return { datePart, timePart };
  },
};
