<template>
  <div>
    <!-- Text -->
    <v-text-field
      v-show="!isTextArea"
      :flat="isLineItem"
      :solo="isLineItem"
      :hide-details="isLineItem ? 'auto' : ''"
      ref="textField"
      v-bind:class="{ 'pt-4': !isLineItem }"
      :disabled="documentMetadata.metadata.isReadonly"
      v-if="documentMetadata"
      v-model="documentMetadata.value"
      :label="label"
      :background-color="highlight ? HIGHLIGHT_BACKGROUND : 'transparent'"
      :append-icon="highlight && documentMetadata.autoExtracted ? 'mdi-auto-fix' : ''"
    ></v-text-field>

    <!-- TextArea -->
    <v-textarea
      v-show="isTextArea"
      :flat="isLineItem"
      :solo="isLineItem"
      :rows="isLineItem ? 2 : 0"
      :auto-grow="isLineItem"
      :hide-details="isLineItem ? 'auto' : ''"
      ref="textArea"
      v-bind:class="{ 'pt-4': !isLineItem }"
      :disabled="documentMetadata.metadata.isReadonly"
      v-if="documentMetadata"
      v-model="documentMetadata.value"
      :label="label"
      :background-color="highlight ? HIGHLIGHT_BACKGROUND : 'transparent'"
      :append-icon="highlight && documentMetadata.autoExtracted ? 'mdi-auto-fix' : ''"
      :data-cy="documentMetadata.metadata.key"
    ></v-textarea>
  </div>
</template>

<script>
import colors from "@/constants/colors";
import metadataType from "@/constants/metadataType";
import documentMetadataService from "@/services/documentMetadataService";

export default {
  name: "TextMetadata",
  props: {
    documentMetadata: Object,
    textSelection: Object,
    isLineItem: Boolean,
    focusOnLoad: Boolean,
    onClickToFill: Function,
  },

  data() {
    return {
      highlight: false,
      HIGHLIGHT_BACKGROUND: colors.HIGHLIGHT_BACKGROUND,
      metadataType,
    };
  },

  created() {
    if (this.documentMetadata.highlight) {
      this.highlight = true;
      setTimeout(() => {
        this.highlight = false;
        this.documentMetadata.autoExtracted = false;
      }, 2000);
    }

    documentMetadataService.focusIfRequested(this);
  },

  computed: {
    isTextArea() {
      return this.documentMetadata.metadata.metadataType.id === metadataType.TextArea;
    },
    label() {
      return this.isLineItem ? "" : this.documentMetadata.metadata.name;
    },
  },

  watch: {
    textSelection() {
      if (this.isTextArea) {
        if (this.$refs.textArea.$el.querySelectorAll("textarea")[0] !== document.activeElement) return;
      } else {
        // Text
        if (this.$refs.textField.$el.querySelectorAll("input")[0] !== document.activeElement) return;
      }

      this.documentMetadata.value = this.textSelection.text;

      documentMetadataService.assignTextSelection(this);

      if (this.onClickToFill) this.onClickToFill();
    },
    focusOnLoad() {
      documentMetadataService.focusIfRequested(this);
    },
  },
};
</script>
