<template>
  <splitpanes class="default-theme splitpanes" horizontal @resize="resizeSplitPanel">
    <pane>
      <div v-bind:class="{ 'horizontal-pane': showBottomPane }">
        <v-navigation-drawer fixed app clipped permanent expand-on-hover :mini-variant.sync="mini">
          <v-list dense nav>
            <v-list-item-group no-action v-model="selectedTab">
              <v-list-item v-for="(item, index) in tabs" :key="index">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <LoadingProgress :loading="loading" />

        <v-navigation-drawer app v-model="drawer" temporary right>
          <v-list-item class="px-2">
            <v-btn icon @click.stop="drawer = !drawer">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <DocumentMetadataList :documentMetadata="documentMetadataWithDocumentType"
            :onLastMetadataClickedToFill="() => (showDetailItems = true)" />
          <DetailItemsToggle v-if="showMetadata && documentType && documentType.lineItemsEnabled"
            :showDetailItems="showDetailItems" :dollarTotalEnabled="dollarTotalEnabled"
            :matchDollarTotal="matchDollarTotal" :detailItemsTotalFormatted="detailItemsTotalFormatted"
            :detailItemsRemainingFormatted="detailItemsRemainingFormatted" :detailItemsTitle="detailItemsTitle"
            :toggleDetailItems="toggleDetailItems" />
        </v-navigation-drawer>
        <v-row style="margin: 0">
          <v-col cols="0" md="4" class="mt-4">
            <DocumentMetadataList v-if="showMetadata" :textSelection="textSelection"
              :documentMetadata="documentMetadataWithDocumentType"
              :onLastMetadataClickedToFill="() => (showDetailItems = true)" />

            <DetailItemsToggle v-if="showMetadata && documentType && documentType.lineItemsEnabled"
              :showDetailItems="showDetailItems" :dollarTotalEnabled="dollarTotalEnabled"
              :matchDollarTotal="matchDollarTotal" :detailItemsTotalFormatted="detailItemsTotalFormatted"
              :detailItemsRemainingFormatted="detailItemsRemainingFormatted" :detailItemsTitle="detailItemsTitle"
              :toggleDetailItems="toggleDetailItems" />
            <DocumentHistory v-if="showHistory" :history="history" :documentMetadata="documentMetadata"
              :lineItemMetadatas="lineItemMetadatas" />
            <DocumentVersions v-if="showVersions" :versions="versions" />
            <DocumentAttachments v-if="showAttachments" :attachments="attachments" :documentId="this.document.id"
              :isReadonly="this.isReadonly" :onAttachmentsChanged="attachmentsChanged" />
            <Payments v-if="showPayments" :documentId="document.id" />
          </v-col>
          <v-col cols="12" md="8">
            <!-- TODO: we should have a slot for rendering additional toolbar actions as opposed to this if maze -->
            <PdfViewer ref="pdfViewer" v-if="initialized" v-show="showPdfViewer" :documentUrl="this.documentUrl"
              :downloadUrl="this.downloadUrl" :id="$route.params.id" :textSelected="textSelected" :isReadonly="isReadonly"
              :canvasWorkingMode="canvasWorkingMode" :signatureImage="signatureImage"><template v-slot:actions>
                <v-btn large @click="cancel" v-show="isMobile && showCancel" icon text class="mr-2">
                  <v-icon left>mdi-delete</v-icon>
                </v-btn>

                <v-btn large @click="cancel" v-show="!isMobile && showCancel" text class="mr-2">
                  <v-icon left>mdi-delete</v-icon>
                  Cancel
                </v-btn>

                <v-btn large @click="close" v-show="isMobile && showClose" icon text class="mr-2">
                  <v-icon left>mdi-close</v-icon>
                </v-btn>

                <v-btn large @click="close" v-show="!isMobile && showClose" text class="mr-2">
                  <v-icon left>mdi-close</v-icon>
                  Close
                </v-btn>

                <v-btn large color="primary" :disabled="isGlobalUser" @click="sign"
                  v-show="showSignAndReturn && !showReturnButton && !isReadonly" class="mr-2">
                  Return
                </v-btn>

                <v-btn color="primary" large @click="save" v-show="!showSignAndReturn && !isReadonly && !showResubmit" data-cy="send">
                  <v-icon left>mdi-send</v-icon>
                  Send
                </v-btn>
                <v-btn color="primary" large @click="replaceFile" v-show="showResubmit && !isReadonly" data-cy="replaceFile" class="button-right-align">
                  <v-icon left>mdi-file-replace-outline</v-icon>
                  Replace file
                </v-btn>

                <v-btn color="primary" large @click="resubmit" v-show="showResubmit && !isReadonly" data-cy="resubmit">
                  <v-icon left>mdi-send</v-icon>
                  Resubmit
                </v-btn>
              </template></PdfViewer>
          </v-col>
        </v-row>
        <v-fab-transition>
          <v-btn color="primary" v-show="isMobile && showPdfViewer" @click="drawer = true" dark fixed bottom right fab
            style="bottom: 1rem">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
      <SignatureDialog
        :show="showSignatureDialog"
        :signatureSize="signatureSize"
        :accept="acceptSignature"
        :skipSignature="changeAddress"
        :close="closeSignatureDialog"
      />
      <ResendFileDialog
        :show="showUploadDialog"
        :documentType="documentType"
        :cancel="() => (showUploadDialog = false)"
        :replaceDocumentId="document && document.id"
        :receivingPartyId="purchaserData.purchaserId"
        :onReplaceFile="onReplaceFile"
      ></ResendFileDialog>
    </pane>
    <pane id="bottom-pane" min-size="20" v-if="showBottomPane" style="background: #fff"
      :size="bottomPanelHeightPercentage">
      <DetailItems v-if="proposedLineItems && showDetailItemsLoaded" :dollarTotal="dollarTotal" :height="detailItemHeight"
        :addRow="addDetailItemRow" :deleteRow="deleteDetailItemRow" :proposedLineItems="proposedLineItems"
        :textSelection="textSelection" :id="$route.params.id" :close="() => (showDetailItems = false)" />
    </pane>
  </splitpanes>
</template>

<script>
import { API_URL } from "../env";
import _ from "lodash"; //TODO: avoid using _ for operations available natively in ES6
import Auth0Mixin from "./Auth0Mixin";
import DocumentMetadataList from "@/components/DocumentMetadataList";
import DetailItemsToggle from "@/components/DetailItemsToggle";
import Payments from "@/components/Payments";
import PdfViewer from "@/components/PdfViewer";
import DocumentHistory from "@/components/DocumentHistory";
import DocumentVersions from "@/components/DocumentVersions";
import DocumentAttachments from "@/components/DocumentAttachments";
import DetailItems from "@/components/DetailItems";
import documentService from "@/services/documentService";
import formattingService from "@/services/formattingService";
import documentMetadataService from "@/services/documentMetadataService";
import documentTypeService from "@/services/documentTypeService";
import historyService from "@/services/historyService";
import { mapGetters } from "vuex";
import LoadingProgress from "@/components/LoadingProgress";
import SignatureDialog from "@/dialogs/SignatureDialog";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import responsiveService from "@/services/responsiveService";
import metadataType from "@/constants/metadataType";
import documentTypeTemplate from "@/constants/documentTypeTemplate";
import documentType from "@/constants/documentType";
import documentRouteStatus from "@/constants/documentRouteStatus";
import canvasWorkingModeValues from "@/constants/documentCanvasWorkingMode";
import validationService from "@/services/validationService";
import metadataKey from "../constants/metadataKey";
import metadataService from "../services/metadataService";
import ResendFileDialog from "@/dialogs/ResendFileDialog.vue";

export default {
  name: "Document",
  mixins: [Auth0Mixin],
  components: {
    DocumentMetadataList,
    LoadingProgress,
    PdfViewer,
    Splitpanes,
    Pane,
    DetailItems,
    DocumentHistory,
    DocumentVersions,
    DocumentAttachments,
    DetailItemsToggle,
    SignatureDialog,
    ResendFileDialog,
    Payments,
  },
  data() {
    return {
      drawer: false,
      documentInitParameters: undefined,
      document: undefined,
      detailItemHeight: 100,
      bottomPanelHeightPercentage: 40,
      documentMetadata: [],
      history: [],
      versions: [],
      attachments: [],
      documentMetadataWithDocumentType: [],
      loading: false,
      showSignatureDialog: false,
      signatureCoordinates: undefined,
      signatureSize: { width: 0, height: 0 },
      documentType: undefined,
      initialized: false,
      textSelection: undefined,
      selectedTab: 0,
      dollarTotalMetadata: undefined,
      proposedLineItems: undefined,
      showDetailItems: false,
      showDetailItemsLoaded: false,
      tabs: [
        { icon: "mdi-file-document-outline", name: "Metadata" },
        { icon: "mdi-history", name: "Audit History" },
        { icon: "mdi-timetable", name: "Versions" },
        { icon: "mdi-paperclip", name: "Attachments" },
      ],
      canvasWorkingMode: canvasWorkingModeValues.CLICK_TO_FILL,
      signatureImage: null,
      showUploadDialog: false,
      newVersionId: null,
      mini: true,
    };
  },

  watch: {
    "$route.params.id": async function () {
      await this.init();
    },
    async selectedTab() {
      if (this.selectedTab === 1) {
        this.history = await historyService.getHistoryForDocument(this.document.id);
      }

      if (this.selectedTab === 2) {
        this.versions = await documentService.getVersions(this.document.id);
      }

      if (this.selectedTab === 3) {
        this.attachments = await documentService.getAttachments(this.document.id);
      }
    },

    async showDetailItems() {
      if (!this.documentType.lineItemsEnabled) return;

      this.showDetailItemsLoaded = false;
      if (this.showBottomPane) {
        setTimeout(() => {
          this.detailItemHeight = 200;
          this.updateDetailItemHeight();
          this.showDetailItemsLoaded = true;
        }, 300);
      }
    },
  },

  computed: {
    ...mapGetters("auth0Store", ["isGlobalUser"]),
    showReturnButton() {
      return this.canvasWorkingMode === canvasWorkingModeValues.CAPTURE_SIGNATURE;
    },

    documentUrl() {
      return this.newVersionId
        ? `${API_URL}/documents/${this.newVersionId}/file/1`
        : `${API_URL}/documents/${this.$route.params.id}/file/1`;
    },
    downloadUrl() {
      return `${API_URL}/download/${this.$route.params.id}`;
    },
    dollarTotalEnabled() {
      return !!this.dollarTotalMetadata;
    },
    showSignAndReturn() {
      return this.document && this.document.documentTypeId === documentType.PENDING_DIVISION_ORDER;
    },
    showResubmit() {
      return this.document?.documentTypeId === 2 && this.document?.routeStatus?.id == documentRouteStatus.Rejected;
    },
    isReadonly() {
      return (
        this.documentType?.documentTypeTemplateId === documentTypeTemplate.DOCUMENT_1099 ||
        this.documentType?.documentTypeTemplateId === documentTypeTemplate.OWNER_STATEMENT ||
        this.documentType?.documentTypeTemplateId === documentTypeTemplate.RUN_STATEMENT ||
        (this.document?.routeStatus !== null &&
          this.document?.routeStatus?.id !== documentRouteStatus.New &&
          this.document?.routeStatus?.id !== documentRouteStatus.Rejected &&
          this.document?.routeStatus?.id !== documentRouteStatus.Pending)
      );
    },
    showCancel() {
      return (
        this.documentType?.documentTypeTemplateId !== documentTypeTemplate.DOCUMENT_1099 &&
        this.documentType?.documentTypeTemplateId !== documentTypeTemplate.PENDING_DIVISION_ORDER &&
        this.documentType?.documentTypeTemplateId !== documentTypeTemplate.OWNER_STATEMENT &&
        this.documentType?.documentTypeTemplateId !== documentTypeTemplate.RUN_STATEMENT &&
        this.document?.routeStatus === null
      );
    },
    showClose() {
      return (
        (this.documentType && this.documentType.documentTypeTemplateId === documentTypeTemplate.PENDING_DIVISION_ORDER) ||
        (this.documentType && this.documentType.documentTypeTemplateId === documentTypeTemplate.OWNER_STATEMENT) ||
        (this.documentType?.documentTypeTemplateId === documentTypeTemplate.AR_INVOICE &&
          this.document?.routeStatus?.id === documentRouteStatus.Rejected) ||
        this.isReadonly
      );
    },
    isMobile() {
      return responsiveService.isMobile(this.$vuetify.breakpoint);
    },
    showPdfViewer() {
      return !this.isMobile || (this.isMobile && this.selectedTab === 0);
    },

    matchDollarTotal() {
      return !!(this.dollarTotal && Math.round(this.dollarTotal * 100) === Math.round(this.detailItemsTotal * 100));
    },

    detailItemsTotal() {
      return this.proposedLineItems ? documentMetadataService.detailItemTotal(this.proposedLineItems.rows) : 0;
    },

    detailItemsTotalFormatted() {
      return formattingService.formatCurrency(this.detailItemsTotal);
    },

    detailItemsRemainingFormatted() {
      let difference = this.dollarTotal - this.detailItemsTotal;
      let formattedDifference = formattingService.formatCurrency(Math.abs(this.dollarTotal - this.detailItemsTotal));
      return difference > 0 ? `${formattedDifference} remaining` : `exceeded by ${formattedDifference}`;
    },

    detailItemsTitle() {
      if (!this.proposedLineItems) return "Show Detail Items";

      const lineItemLength = this.proposedLineItems.rows.filter(r => _.some(r.cells, c => !!c.value)).length;
      return `Show Detail Items (${lineItemLength})`;
    },

    showBottomPane() {
      return this.selectedTab === 0 && this.showDetailItems && this.documentType.lineItemsEnabled;
    },

    showMetadata() {
      return this.selectedTab === 0;
    },

    showHistory() {
      return this.selectedTab === 1;
    },
    showPayments() {
      return this.tabs[this.selectedTab].name === "Payments";
    },
    purchaserData() {
      if (!this.document || !this.documentMetadata) return {};
      return {
        templateId: this.documentType?.documentTypeTemplateId,
        purchaserId:
          this.document.documentTypeId === 2 && !isNaN(this.documentMetadata?.find(x => x.metadata.key === "docPurchaserName")?.value)
            ? Number(this.documentMetadata?.find(x => x.metadata.key === "docPurchaserName")?.value)
            : undefined,
      };
    },
    showVersions() {
      return this.selectedTab === 2;
    },

    showAttachments() {
      return this.selectedTab === 3;
    },

    dollarTotal() {
      return this.dollarTotalEnabled && this.dollarTotalMetadata && this.dollarTotalMetadata.value
        ? Number(this.dollarTotalMetadata.value)
        : 0;
    },

    dollarTotalFormatted() {
      return formattingService.formatCurrency(this.dollarTotal);
    },

    lineItemMetadatas() {
      return this.proposedLineItems.rows[0].cells.map(x => x.metadata);
    },
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token", "user"]),
  },

  methods: {
    async init() {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      this.initialized = true;
      let id = this.$route.params.id;
      this.document = await documentService.getById(id);
      let documentTypeId = this.document.documentTypeId;
      this.documentType = await documentTypeService.get(documentTypeId);
      let allMetadataField = this.document.metadata.filter(dm => !dm.metadata.isDocumentProperty);
      this.dollarTotalMetadata = allMetadataField.find(dm => dm.metadata.isDollarTotal);
      let autoPopulatedMetadata = await documentService.getAutoExtract(id, documentTypeId);

      // Sets the auto populated values and highlight flag
      documentMetadataService.populateAutoExtracted(id, allMetadataField, autoPopulatedMetadata);
      if (this.documentType?.documentTypeTemplateId === documentTypeTemplate.AR_INVOICE)
        this.tabs.push({ icon: "mdi-currency-usd", name: "Payments" });

      if (documentRouteStatus.Rejected === this.document.routeStatusId) {
        let reason = await historyService.getRejectionReason(id);
        this.$root.$snackbar.warn(reason, "Rejection Reason", true);
      }
      this.documentMetadata = allMetadataField;

      this.documentMetadataWithDocumentType = this.documentMetadata.filter(
        dm => dm.metadata.metadataType.id !== metadataType.DocumentType
      );

      this.proposedLineItems = await documentService.getProposedLineItems(id, documentTypeId);

      this.document.lineItems = this.proposedLineItems.rows;
      this.detailItemHeight = 200;
      this.updateDetailItemHeight();

      await documentService.markRead(id);
      this.$vuetify.goTo(0, 0); // G.K: To refactor the whole page later. Something is scrolling the doc to bottom.
    },
    async acceptSignature(base64Image) {
      this.showSignatureDialog = false;
      var signatureCount = this.document.documentSignatures.push({
        imageAsBase64: base64Image,
        userId: this.user.id,
      });
      this.signatureImage = "data:image/png;base64, " + base64Image;
      const coordinates = {
        x: this.signatureCoordinates.x,
        y: this.signatureCoordinates.y,
        width: this.signatureCoordinates.width,
        height: this.signatureCoordinates.height,
      };

      let lastSign = this.document.documentSignatures[signatureCount - 1];
      lastSign.area = coordinates;
      lastSign.page = this.$refs.pdfViewer.currentPage;
      const statusMetadata = this.document.metadata.find(
        m => m.metadata.key === metadataKey.DivisionOrderResponseStatus
      );
      const values = await metadataService.getLookupValues(statusMetadata.metadata.id, false);
      const signed = values.find(v => v.lookupValue === "Returned Signed");

      statusMetadata.value = signed.id;
      statusMetadata.lookupValue = signed.id;

      this.save();
    },

    toggleDetailItems() {
      this.showDetailItems = !this.showDetailItems;
      if (this.showDetailItems) this.drawer = false;
    },

    updateDetailItemHeight() {
      let bottomPane = document.getElementById("bottom-pane");
      this.detailItemHeight = bottomPane ? bottomPane.clientHeight - 120 : 0;
    },

    async textSelected(text, coordinates) {
      if (this.canvasWorkingMode === canvasWorkingModeValues.CAPTURE_SIGNATURE) {
        this.signatureCoordinates = coordinates;
        const scale = this.$refs.pdfViewer.scale;
        const width = this.signatureCoordinates.width * this.$refs.pdfViewer.width * scale;
        const height = this.signatureCoordinates.height * this.$refs.pdfViewer.height * scale;
        this.signatureSize = { width, height };

        this.showSignatureDialog = true;
        this.canvasWorkingMode === canvasWorkingModeValues.CLICK_TO_FILL;
      } else {
        this.textSelection = { text, coordinates };
      }
    },

    addDetailItemRow() {
      let clone = JSON.parse(JSON.stringify(this.proposedLineItems.rows[0]));
      clone.cells.forEach(cell => (cell.value = null));
      this.proposedLineItems.rows.push(clone);
    },

    deleteDetailItemRow(index) {
      if (this.proposedLineItems.rows.length > 1) {
        this.proposedLineItems.rows.splice(index, 1);
      } else {
        documentMetadataService.clearAllValues(this.proposedLineItems.rows[0].cells);
      }
    },

    resizeSplitPanel(args) {
      this.bottomPanelHeightPercentage = args[1].size;
      this.updateDetailItemHeight();
    },

    async attachmentsChanged() {
      this.attachments = await documentService.getAttachments(this.document.id);
    },

    async cancel() {
      let answer = await this.$root.$confirmDialog.open("Are you sure?", "Cancel sending of this document?", "Yes");

      if (!answer) {
        return;
      }

      if (this.documentType.documentTypeTemplateId === documentTypeTemplate.AR_INVOICE) {
        this.loading = true;
        await documentService.cancel(this.document.id);
        this.loading = false;
      }

      this.$root.$snackbar.message("Canceled");
      this.drawer = false;
      this.$router.push("/");
    },
    close() {
      this.$router.push(`/documentlist/${this.documentType.documentTypeTemplateId}`);
    },
    async sign() {
      const result = await this.validate();
      if (!result) return;
      if (this.isMobile) {
        await this.$root.$confirmDialog.open(
          "Return Division Order",
          "In order to sign and return the division order you need to use the desktop version of the application",
          "Ok",
          { hideCancel: true }
        );
      } else {
        this.canvasWorkingMode = canvasWorkingModeValues.CAPTURE_SIGNATURE;
        this.$root.$snackbar.message("Click and drag on the document to select the location of your signature.");
      }
    },

    async changeAddress() {
      let statusMetadata = this.document.metadata.find(m => m.metadata.key === metadataKey.DivisionOrderResponseStatus);
      let values = await metadataService.getLookupValues(statusMetadata.metadata.id, false);
      let returnUnsigned = values.find(v => v.lookupValue === "Returned Unsigned");

      statusMetadata.value = returnUnsigned.id;
      statusMetadata.lookupValue = returnUnsigned.id;

      await this.save();
    },
    closeSignatureDialog() {
      this.showSignatureDialog = false;
      this.canvasWorkingMode = canvasWorkingModeValues.CLICK_TO_FILL;
    },
    async replaceFile() {
      this.showUploadDialog = true;
    },
    async resubmit() {
      this.loading = true;
      await documentService.resubmit(this.document.id, this.document, this.newVersionId);
      this.loading = false;
      this.$router.push(`/documentlist/${this.documentType.documentTypeTemplateId}`);
    },
    async save() {
      const result = await this.validate();
      if (result) {
        try {
          this.loading = true;
          await documentService.publish(this.document);
          this.loading = false;
          this.$root.$snackbar.message("Document Sent");
          this.drawer = false;
          this.$router.push(`/documentlist/${this.documentType.documentTypeTemplateId}`);
        } catch (e) {
          const error = e.response.data.error;
          this.$root.$snackbar.error(error.message);
          this.loading = false;
        }
      }
    },

    async validate() {
      const validation = validationService.validateDocument(this.document);
      if (!validation.isValid) {
        await this.$root.$snackbar.error(validation.errorMessage);
        return false;
      }

      const hasEnteredAnyLineItems = documentMetadataService.hasAnyLineItems(this.document.lineItems);
      if (
        this.documentType.lineItemsEnabled &&
        this.dollarTotalEnabled &&
        !this.matchDollarTotal &&
        hasEnteredAnyLineItems
      ) {
        var errorMessage = `Detail item total (${this.detailItemsTotalFormatted}) does not match ${this.dollarTotalMetadata.metadata.name} (${this.dollarTotalFormatted})`;
        await this.$root.$snackbar.error(errorMessage);
        return false;
      }

      return true;
    },

    async onReplaceFile(newDocId) {
      if (this.newVersionId) {
        try {
          await documentService.deleteTemporaryDocument(this.newVersionId);
        } catch (ex) {
          // don't break the process of replacing the file because we cannot delete the temporary file.
          // the temporary file won't effect anything
        }
      }
      this.newVersionId = newDocId;
    },
  },
};
</script>

<style scoped>
.horizontal-pane {
  width: 100%;
  overflow: auto;
}

.splitpanes {
  height: calc(100vh - 60px);
}

.splitpanes__pane {
  overflow: auto;
}

.button-right-align {
  margin-right: 5px;
}
</style>
