<template>
  <div class="ml-4">
    <div>
      <h3 class="float-left">
        <v-switch
          class="mt-0"
          :value="showDetailItems"
          @change="toggleDetailItems()"
          :label="detailItemsTitle"
        ></v-switch>
      </h3>
      <div class="pr-4 float-right" v-show="dollarTotalEnabled">
        <h3 v-bind:class="{ 'green--text': matchDollarTotal, 'grey--text': !matchDollarTotal }">
          Detail Total: {{ detailItemsTotalFormatted }}
        </h3>
        <div v-show="!matchDollarTotal" class="caption float-right">{{ detailItemsRemainingFormatted }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailItemsToggle",
  props: {
    showDetailItems: Boolean,
    dollarTotalEnabled: Boolean,
    matchDollarTotal: Boolean,
    detailItemsTotalFormatted: String,
    detailItemsRemainingFormatted: String,
    detailItemsTitle: String,
    toggleDetailItems: Function,
  },

  data() {
    return {};
  },
};
</script>
