<template>
  <v-dialog v-model="show" persistent max-width="1200" @click:outside="cancel">
    <v-card>
      <v-card-text class="title pt-4">{{ title }}</v-card-text>
      <v-card-text>
        <v-row v-for="hm in headerMetadata" :key="hm.id">
          <v-col cols="2">
            <span>{{ hm.field }}</span>
          </v-col>

          <v-col cols="10">
            <span v-if="hm.isEqual">{{ hm.after }}</span>
            <span v-if="!hm.isEqual">
              <span class="text-decoration-line-through">{{ hm.before }}</span>
              <span class="green--text pl-2">{{ hm.after }}</span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <div v-if="this.comment">{{ this.comment }}</div>
        <v-data-table
          disable-pagination
          :hide-default-footer="true"
          :must-sort="true"
          :no-data-text="''"
          :headers="lineItemHeaders"
          :items="lineItems"
        >
          <template v-for="slot in lineItemHeaders" v-slot:[`item.${slot.value}`]="{ item }">
            <div :key="slot.value">
              <span v-if="item[slot.value].isEqual">{{ item[slot.value].newValue }}</span>

              <span v-if="!item[slot.value].isEqual">
                <span class="text-decoration-line-through">{{ item[slot.value].oldValue }}</span>
                <span class="green--text pl-2">{{ item[slot.value].newValue }}</span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import historyService from "@/services/historyService";
import { mapState } from "vuex";

export default {
  name: "HistoryDetailDialog",

  props: {
    title: String,
    historyDetails: Array,
    documentMetadata: Array,
    close: Function,
    lineItemMetadatas: Array,
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),

    show() {
      return this.historyDetails && this.historyDetails.length > 0;
    },
  },

  data() {
    return {
      comment: "",
      headerMetadata: [],
      lineItemHeaders: [],
      lineItems: [],
    };
  },

  watch: {
    historyDetails() {
      if (this.historyDetails.length === 0) return;

      let lineItemHistory = historyService.extractLineItemsHistory(
        this.historyDetails,
        this.dateFormat,
        this.lineItemMetadatas
      );

      this.lineItems = lineItemHistory.lineItems;
      this.lineItemHeaders = lineItemHistory.lineItemHeaders;

      this.headerMetadata = historyService.extractHeaderHistory(
        this.historyDetails,
        this.documentMetadata,
        this.dateFormat
      );

      this.comment = historyService.extractCommentHistory(this.historyDetails);
    },
  },

  methods: {
    cancel() {
      this.close(false);
    },
  },
};
</script>
