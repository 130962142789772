import userServices from "@/services/userService";
export default {
  namespaced: true,

  state: {
    dateFormat: undefined,
    numericFormat: undefined,
    currencyFormat: undefined,
    itemsPerPage: undefined,
    unreadNotifications: 0,
  },

  mutations: {
    updateProfile: (state, profile) => {
      profile.forEach(x => (state[x.key] = x.value));
    },
    setUnreadNotification: (state, value) => {
      state.unreadNotifications = value;
    },
    addUnreadNotification: state => {
      ++state.unreadNotifications;
    },
  },
  getters: {
    profile: state => ({ ...state, receiveEmails: state.receiveEmails === "true" }),
    dateFormat: state => (state.dateFormat ? state.dateFormat.toUpperCase() : ""),
    unreadNotifications: state => state.unreadNotifications,
  },
  actions: {
    initUserProfile({ commit }, profileItems) {
      commit("updateProfile", profileItems);
    },
    saveUserPreferences({ commit }, profile) {
      var profileItems = Object.keys(profile).map(x => ({ key: x, value: profile[x].toString() }));
      userServices.saveUserPersonalization(profileItems);
      commit("updateProfile", profileItems);
    },
    setUnreadNotification({ commit }, value) {
      commit("setUnreadNotification", value);
    },
    addUnreadNotification({ commit }) {
      commit("addUnreadNotification");
    },
  },
};
