<template>
  <v-container fluid>
    <v-card class="elevation-1 card mt-4 pa-4 card-form">
      <v-card-title class="pa-4">
        Accept Invite
      </v-card-title>
      <v-card-text v-if="!pathSelected" class="wizard">
        <v-radio-group label="Existing or new party" v-model="process">
          <v-radio label="Create new party" value="new" />
          <v-radio label="Select existing party" value="existing" />
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="showExistingParty" class="wizard">
        <v-card-subtitle class="pa-4">Select existing party</v-card-subtitle>
        <v-combobox
          label="Party"
          placeholder="Select existing party"
          :items="filteredParties"
          v-model="party"
        ></v-combobox>
      </v-card-text>
      <v-card-text v-if="showNewParty" class="wizard">
        <v-card-subtitle class="pa-4">Create new party</v-card-subtitle>
        <v-select
          label="Type"
          class="mt-4 mr-4"
          :items="partyTypeList"
          v-model="partyTypeId"
          item-text="name"
          item-value="id"
          v-if="!invitation.disablePartyType"
        ></v-select>
        <v-select
          v-if="showOrgUnits"
          v-model="orgUnitIds"
          :items="invitation.orgUnits"
          label="Org Units"
          item-text="name"
          item-value="id"
          multiple
          chips
          persistent-hint
        ></v-select>
        <v-text-field v-model="partyNumber" label="Party Number" required />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="previous()" :disabled="!pathSelected" large color="primary" depressed>Previous</v-btn>
        <v-btn @click="next()" :disabled="pathSelected" large color="primary" depressed>Next</v-btn>
        <v-spacer />
        <v-btn @click="cancel()" large color="primary" depressed>Cancel</v-btn>
        <v-btn @click="accept()" large color="primary" depressed :disabled="disableAccept">Accept</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import partyTypeList from "@/constants/partyTypeList";
import invitationService from "@/services/invitationService";
import userService from "@/services/userService";
import logException from "@/services/exceptionLoggingService";

export default {
  name: "Invitation",
  props: {
    invitationId: Number,
    invitation: Object,
  },
  mixins: [Auth0Mixin],
  data() {
    return {
      pathSelected: false,
      process: "new",
      partyTypeList,
      partyTypeId: this.invitation.partyTypeId,
      orgUnitIds: this.invitation.orgUnitIds.length === 1 ? this.invitation.orgUnitIds : [],
      partyNumber: null,
      party: null,
      parties: this.invitation.sourceParties,
    };
  },
  computed: {
    showNewParty() {
      return this.pathSelected && this.process === "new";
    },
    showExistingParty() {
      return this.pathSelected && this.process === "existing";
    },
    showOrgUnits() {
      return this.invitation.orgUnits?.length > 1;
    },
    disableAccept() {
      if (!this.pathSelected) return true;
      if (this.process === "new") return !this.partyNumber || !this.partyTypeId || !this.orgUnitIds.length;
      return !this.party;
    },
    filteredParties() {
      return this.parties
        .filter(x => !x.connectedCompanyId || x.connectedCompanyId === this.invitation.senderCompanyId)
        .map(x => ({ text: x.name, value: x.id }));
    },
  },
  methods: {
    init() {},
    next() {
      this.pathSelected = true;
    },
    previous() {
      this.pathSelected = false;
    },
    cancel() {
      this.$router.push({ name: "InvitationList" });
    },
    async accept() {
      let answer = await this.$root.$confirmDialog.open(
        "Accept",
        "By clicking continue, you agree that you have done any applicable due diligence specific to your company policies relevant to accepting documents from outside parties",
        "Continue"
      );

      if (!answer) {
        return;
      }

      try {
        let partyTypeIdValue;
        if (this.process === "existing") {
          var party = this.parties.find(x => x.id === this.party.value);
          partyTypeIdValue = party.partyTypeId;
        } else {
          partyTypeIdValue = this.partyTypeId;
        }
        let invitationAcceptance = {
          id: this.invitationId,
          partyNumber: this.process === "new" ? this.partyNumber : "",
          partyTypeId: partyTypeIdValue,
          orgUnitIds: this.process === "new" ? this.orgUnitIds.map(x => x) : [],
          existingPartyId: this.process === "new" ? null : this.party.value,
        };
        await invitationService.accept(invitationAcceptance);
        const exchangeUser = await userService.getUserByExternalId(
          decodeURI(this.$auth.user.sub),
          this.selectedCompanyId
        );
        this.$store.dispatch("documentTypeStore/updateDocumentTypes", { documentTypes: exchangeUser.documentTypes });
        this.$store.dispatch("invitationStore/removePendingInvitation");
        this.$root.$snackbar.message("You are now connected to " + this.invitation.companyName);
        this.$router.push({ name: "InvitationList" });
      } catch (err) {
        this.$root.$snackbar.error(err.response?.data?.error?.message || "Couldn't accept invitation'");
        logException.logException(err, "Accept invitation", "Invitation Page");
        return;
      }
    }
  }
}
</script>

<style scoped>
.wizard {
  height: 300px;
}
</style>
