<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-icon left>group_work</v-icon>
        Org Unit
        <v-spacer />
        <v-btn color="primary" @click="addNew" class="ml-4"> <v-icon>mdi-plus</v-icon> New Org Unit </v-btn>
      </v-card-title>
      <div class="ml-4 mb-4">
        <v-text-field
          v-model="searchQuery"
          prepend-icon="mdi-magnify"
          :label="searchLabel"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
      </div>
      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="orgunits"
        :loading="loading"
        :search="searchQuery"
        @click:row="item => selectOrgUnit(item)"
      >
        <template v-slot:item.icon="{}">
          <v-icon>group_work</v-icon>
        </template>
        <template v-slot:item.name="{ item }">
          <div v-html="highlight(item.name, searchQuery)"></div>
        </template>
        <template v-slot:item.code="{ item }">
          <div v-html="highlight(item.code, searchQuery)"></div>
        </template>
        <template v-slot:item.addressLineOne="{ item }">
          <div v-html="highlight(item.addressLineOne, searchQuery)"></div>
        </template>
        <template v-slot:item.addressLineTwo="{ item }">
          <div v-html="highlight(item.addressLineTwo, searchQuery)"></div>
        </template>
        <template v-slot:item.city="{ item }">
          <div v-html="highlight(item.city, searchQuery)"></div>
        </template>
        <template v-slot:item.zipCode="{ item }">
          <div v-html="highlight(item.zipCode, searchQuery)"></div>
        </template>
        <template v-slot:item.phone="{ item }">
          <div v-html="highlight(item.phone, searchQuery)"></div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import orgUnitService from "@/services/admin/orgUnitService";
import Auth0Mixin from "../Auth0Mixin";
import filterService from "@/services/filterService.js";
import searchUtilsService from "@/services/searchUtilsService.js";
import localStorageService from "@/services/localStorageService.js";
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";

export default {
  name: "OrgUnits",
  mixins: [Auth0Mixin],
  data() {
    let state = localStorageService.get("orgUnitList");

    return {
      headers: [
        { text: "", value: "icon", width: 40 },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "Address line 1", value: "addressLineOne" },
        { text: "Address line 2", value: "addressLineTwo" },
        { text: "City", value: "city" },
        { text: "Zip Code", value: "zipCode" },
        { text: "Phone number", value: "phone" },
        {
          text: "Is Active",
          value: "isActive",
        },
      ],
      orgunits: [],
      searchQuery: state ? state.query : "",
      loading: true,
      highlight: filterService.highlight,
    };
  },

  watch: {
    searchQuery: function() {
      localStorageService.save("orgUnitList", { query: this.searchQuery });
    },
  },

  computed: {
    searchLabel: function() {
      return searchUtilsService.getLabel(terminologyService.lookup(terminology.ORG_UNIT), this.orgunits);
    },
  },

  methods: {
    async init() {
      const result = await orgUnitService.getList(false);
      this.orgunits = result.items;
      this.loading = false;
    },

    async addNew() {
      this.$router.push(`/admin/orgunit/create`);
    },

    selectOrgUnit(orgunit) {
      this.$router.push(`/admin/orgunit/${orgunit.id}`);
    },
  },
};
</script>
