<template>
  <v-container fluid> </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import { mapGetters } from "vuex";
import invitationService from "@/services/invitationService";
// import role from "@/constants/role";
export default {
  name: "OnBoarding",
  mixins: [Auth0Mixin],
  props: {
    invitationToken: String,
  },
  data() {
    return {
      exCompanies: [],
      isSubmitInvitaion: this.$route.query.submitInvitation === "true"
    }
  },
  computed: {
    ...mapGetters("auth0Store", ["companies", "isAdmin", "selectedCompanyId"]),
  },
  methods: {
    async init() {
      this.exCompanies = this.companies;
      if (this.exCompanies.length > 1 && !this.isSubmitInvitaion) {
        await this.showCompanyOptions();
      } else {
        try {
          if (this.exCompanies[0].isCloud) {
            //authService.loginWithRedirect({ appState: { targetUrl: `/admin/invitations/${this.company.invitationToken}` } });
          } else {
            await invitationService.onBoard(this.invitationToken);
            await this.$root.$confirmDialog.open("Sent Invitation", "You Sent an invitation. You need to wait for the company to accept or reject the invitation.", "Continue", { hideCancel: true });
            this.$router.push({ path: `/` });
          }
        } catch (e) {
          this.$root.$snackbar.exception(e, true);
          this.$router.push({ path: `/` });
        }
      }
    },
    async showCompanyOptions() {
      try {
        let result = await this.$root.$inputListDialog.showListOptions(
          "Invite a company",
          this.exCompanies,
          "Choose the company you want to send the invitation from",
          { persistent: true }
        );
        if (result.id === this.selectedCompanyId) {
          try {
            await invitationService.onBoard(this.invitationToken);
            await this.$root.$confirmDialog.open("Sent Invitation", "You Sent an invitation. You need to wait for the company to accept or reject the invitation.", "Continue", { hideCancel: true });
            this.$router.push({ path: `/` });
          } catch (e) {
            this.$root.$snackbar.exception(e, true);
            this.$router.push({ path: `/` });
          }
        } else {
          if (result.isCloud) {
            var url = `${result.url}/admin/invitations/onboarding/${this.invitationToken}`;
            window.location.href = url;
          } else {
            this.$store.dispatch("auth0Store/updateCompanyId", { selectedCompanyId: result.id });
            window.location.href = `/admin/onboarding/${this.$route.params.invitationToken}?submitInvitation=true`;
          }
        }
      } catch (e) {
        // Should come from cancel the options
      }
    },
  }
};
</script>
