<template>
  <v-container fluid>
    <v-card class="elevation-1 card pa-4">
      <p class="mt-4">Your company logo will be resized to 250px wide and 50px tall</p>
      <FileUploader :success="onFileUploaded" url="/companyImage/upload" label="Choose a file or drag it here" />
    </v-card>
  </v-container>
</template>
<script>
import Auth0Mixin from "../Auth0Mixin";
import FileUploader from "@/components/FileUploader";
import logoService from "@/services/logoService";

import { mapGetters, mapMutations } from "vuex";
export default {
  mixins: [Auth0Mixin],
  components: { FileUploader },

  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token"]),
  },

  data() {
    return {
      dropzoneOptions: undefined,
    };
  },

  methods: {
    ...mapMutations("logoStore", ["updateLogo"]),

    async init() {},

    async onFileUploaded() {
      const logoData = await logoService.getLogoAsBase64();
      this.updateLogo(logoData);
    },
  },
};
</script>
