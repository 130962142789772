<template>
  <v-container fluid> </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import { mapGetters } from "vuex";
import invitationService from "@/services/invitationService";
// import role from "@/constants/role";
export default {
  name: "RegisterInvitation",
  mixins: [Auth0Mixin],
  computed: {
    ...mapGetters("auth0Store", ["companies", "isAdmin", "selectedCompanyId"]),
  },
  methods: {
    async showConfirmDialog() {
      let answer = await this.$root.$confirmDialog.open(
        "Accept",
        "By clicking continue, you agree that you have done any applicable due diligence specific to your company policies relevant to accepting documents from outside parties",
        "Continue"
      );

      return answer;
    },
    async moveToCompany(company) {
      if (company.isCloud) {
        var companyurl = `${company.url}/admin/invitations/list`;
        window.location.href = companyurl;
      } else {
        this.$store.dispatch("auth0Store/updateCompanyId", { selectedCompanyId: company.id });
        window.location.href = `/admin/invitations/`;
      }
    },
    async init() {
      try {
        if (this.$route.params.invitationToken) {
          await invitationService.getInvitationByToken(this.$route.params.invitationToken);
        }
        if (this.companies.length > 1 && this.$route.query.submitInvitation !== "true") {
          await this.showCompanyOptions();
        } else {
          if (this.$route.query.submitInvitation !== "true" && this.companies[0].isCloud) {
            var company = this.companies[0];
            var companyurl = `${company.url}/admin/invitations/list/${this.$route.params.invitationToken}`;
            window.location.href = companyurl;
          } else {
            await invitationService.register(this.$route.params.invitationToken);
            if (!this.isAdmin) {
              await this.$root.$confirmDialog.open("Received Invitation", "You received an invitation. Ask your admin to accept or reject the invitation.", "Continue", { hideCancel: true });
              this.$router.push({ path: `/` });
            } else {
              this.$router.push({ path: `/admin/invitations` });
            }
          }
        }
      } catch (ex) {
        var errorData = ex.response.data;
        if (errorData.status === 400 && errorData.error.message === "Invitation not found") {
          await this.$root.$confirmDialog.open("Invitation Not found", "Invitation not found, or expired", "Continue", { hideCancel: true });
          let anyExchngeCompany = this.companies.find(x => !x.isCloud);
          this.moveToCompany(anyExchngeCompany ?? this.companies[0]);
        } else {
          this.$root.$snackbar.exception(ex);
          this.$router.push({ path: `/admin/invitations` });
        }
      }
    },
    async showCompanyOptions() {
      try {
        let result = await this.$root.$inputListDialog.showListOptions(
          "You have an invitation",
          this.companies,
          "Choose the company you want to save the invitation to",
          { persistent: true }
        );
        if (result.id === this.selectedCompanyId) {
          await invitationService.register(this.$route.params.invitationToken);
          if (this.isAdmin) {
            this.$router.push({ path: `/admin/invitations` });
          } else {
            await this.$root.$confirmDialog.open("Received Invitation", "You received an invitation. Ask your admin to accept or reject the invitation.", "Continue", { hideCancel: true });
            this.$router.push({ path: `/` });
          }
        } else {
          if (result.isCloud) {
            var companyurl = `${result.url}/admin/invitations/list/${this.$route.params.invitationToken}`;
            window.location.href = companyurl;
          } else {
            this.$store.dispatch("auth0Store/updateCompanyId", { selectedCompanyId: result.id });
            window.location.href = `/admin/invitations/${this.$route.params.invitationToken}?submitInvitation=true`;
          }
        }
      } catch (e) {
        // Should come from cancel the options
      }
    },
  },
};
</script>
