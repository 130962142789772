var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("person")]),_vm._v(" Users "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New User ")],1)],1),_c('div',{staticClass:"ml-4 mb-4"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"prepend-icon":"mdi-magnify","label":_vm.searchLabel,"single-line":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{staticClass:"ma-1",attrs:{"disable-pagination":"","hide-default-footer":true,"must-sort":true,"no-data-text":'',"headers":_vm.headers,"items":_vm.users,"options":_vm.tableOptions,"search":_vm.searchQuery,"loading":_vm.loading},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":function (item) { return _vm.selectUser(item); }},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){return [_c('v-icon',[_vm._v("person")])]}},{key:"item.emailAddress",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ inactive: !item.isActive },domProps:{"innerHTML":_vm._s(_vm.highlight(item.emailAddress, _vm.searchQuery))}})]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.firstName, _vm.searchQuery))}})]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.lastName, _vm.searchQuery))}})]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isActive ? "yes" : "no")+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }