export default {
  Text: 1,
  Numeric: 2,
  Currency: 3,
  Date: 4,
  DateTime: 5,
  List: 6,
  User: 7,
  DocumentType: 8,
  Vendor: 9,
  OrgUnit: 10,
  AFE: 11,
  CostCenter: 12,
  AccountCode: 13,
  Deck: 14,
  Well: 15,
  ApprovalUser: 16,
  ApprovalWorkgroup: 17,
  Owner: 18,
  Operator: 19,
  Property: 20,
  Bank: 21,
  Party: 22,
  TextArea: 23,
  Purchaser: 24,
};
