const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  formatCurrency: value => {
    return numberFormatter.format(value);
  },
};
