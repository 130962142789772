export default {
  DivisionOrderResponseStatus: "docDivisionOrderResponseStatus",
  DivisionOrderAddressLineOne: "docDivisionOrderAddressLineOne",
  DivisionOrderAddressLineTwo: "docDivisionOrderAddressLineTwo",
  DivisionOrderCity: "docDivisionOrderCity",
  DivisionOrderState: "docDivisionOrderState",
  DivisionOrderZipCode: "docDivisionOrderZipCode",
  InvoiceVendorName: "docVendorName",
  InvoiceInvoiceNumber: "docInvoiceNumber",
  InvoiceNumber: "docDocumentDate",
  InvoiceServiceDate: "docServiceDate",
  InvoiceInvoiceTotal: "docInvoiceTotal",
  InvoiceScanDate: "docScanDate",
  InvoiceOrgUnitName: "docOrgUnitName",
  InvoiceQuantity: "docQuantity"
};
