<template>
  <v-dialog
    v-model="show"
    persistent
    v-if="attachment !== null"
    max-width="65%"
    @click:outside="close"
    @keydown.esc="close"
    style="background-color: white;"
    content-class="attachment"
  >
    <div>
      <div class="title pt-4 text-center">{{ attachment.fileName }}</div>
      <PdfViewer :documentUrl="documentUrl" :downloadUrl="downloadUrl" :isReadonly="true" :zoomDisabled="true">
        <template v-slot:actions>
          <v-btn large @click="close()" text class="mr-2">
            <v-icon left>mdi-close</v-icon>
            Close
          </v-btn>
        </template>
      </PdfViewer>
    </div>
  </v-dialog>
</template>

<script>
import { API_URL } from "../env";
import PdfViewer from "@/components/PdfViewer";

export default {
  name: "AttachmentDialog",
  components: { PdfViewer },
  props: {
    attachment: Object,
    show: Boolean,
    close: Function,
  },
  data() {
    return {};
  },
  init() {},
  computed: {
    documentUrl() {
      return `${API_URL}/download/attachment/${this.attachment.id}/pdf`;
    },
    downloadUrl() {
      return `${API_URL}/download/attachment/${this.attachment.id}`;
    },
  },
  methods: {},
};
</script>
<style>
.attachment {
  background-color: #fff;
  width: 100%;
  overflow-y: scroll;
}
</style>
