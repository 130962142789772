import axios from "axios";
import { API_URL } from "../env";
import store from "../store/index";

const options = () => {
  return {
    headers: {
      Authorization: `Bearer ${store.getters["auth0Store/auth0Token"]}`,
      "Company-Id": store.getters["auth0Store/selectedCompanyId"],
    },
  };
};

const getFullUrl = uri => (uri.indexOf("http") === 0 ? uri : `${API_URL}${uri}`);

export default {
  get: async (uri, params = {}) => {
    const { data } = await axios.get(getFullUrl(uri), Object.assign(options(), { params }));
    return data;
  },

  getBinary: async uri => {
    return await axios.get(getFullUrl(uri), { responseType: "arraybuffer", ...options() });
  },

  post: async (uri, params) => {
    const { data } = await axios.post(getFullUrl(uri), params, options());
    return data;
  },

  postWithNoCompanyId: async (uri, params) => {
    var _options = options();
    delete _options.headers["Company-Id"];
    const { data } = await axios.post(getFullUrl(uri), params, _options);
    return data;
  },

  postFile: async (uri, file, fileExtraData) => {
    let opts = options();
    opts.headers["Content-Type"] = "multipart/form-data";
    opts.headers["Company-Id"] = store.getters["auth0Store/selectedCompanyId"];

    var formData = new FormData();
    formData.append("file", file);
    if (fileExtraData)
      Object.keys(fileExtraData).forEach(key => formData.append(key, JSON.stringify(fileExtraData[key])));

    const { data } = await axios.post(getFullUrl(uri), formData, opts);
    return data;
  },

  putFile: async (uri, file, fileExtraData) => {
    let opts = options();
    opts.headers["Content-Type"] = "multipart/form-data";
    opts.headers["Company-Id"] = store.getters["auth0Store/selectedCompanyId"];

    var formData = new FormData();
    formData.append("file", file);
    if (fileExtraData)
      Object.keys(fileExtraData).forEach(key => formData.append(key, JSON.stringify(fileExtraData[key])));

    const { data } = await axios.put(getFullUrl(uri), formData, opts);
    return data;
  },

  put: async (uri, params) => {
    const { data } = await axios.put(getFullUrl(uri), params, options());
    return data;
  },

  deleteRequest: async uri => {
    const { data } = await axios.delete(getFullUrl(uri), options());
    return data;
  },
};
