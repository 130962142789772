<template>
  <v-container fluid>
    <v-card-title class="pa-4"><v-icon left>mdi-inbox</v-icon> Inbox</v-card-title>
    <v-card>
      <v-card-title class="pa-4">
        <v-text-field
          v-model="searchQuery"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="documents"
        :loading="loading"
        :search="searchQuery"
        @click:row="item => selectDocument(item)"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import documentRouteService from "@/services/documentRouteService.js";
import localStorageService from "@/services/localStorageService.js";
import filterService from "@/services/filterService.js";
import Auth0Mixin from "./Auth0Mixin";

export default {
  name: "InboxDocuments",
  mixins: [Auth0Mixin],
  data() {
    let state = localStorageService.get("inboxDocuments");
    return {
      headers: [],
      documents: [],
      searchQuery: state ? state.query : "",
      loading: true,
      highlight: filterService.highlight,
    };
  },

  watch: {
    searchQuery: function() {
      localStorageService.save("inboxDocuments", { query: this.searchQuery });
    },
  },

  methods: {
    async init() {
      this.resultSet = await documentRouteService.getInboxDocuments();

      const columnHeaders = this.resultSet.columnHeaders.map(column => {
        return { text: column.name, value: column.key };
      });
      this.headers = [{ text: "Doc #", value: "id" }, ...columnHeaders];

      const documents = this.resultSet.items.map(item => {
        let document = { id: item.id };
        item.metadata.forEach(metadata => {
          document[metadata.metadata.key] = metadata.value;
        });
        return document;
      });

      this.documents = documents;
      this.loading = false;
    },

    selectDocument(item) {
      this.$router.push({ name: "Document", params: { id: item.id } });
    },
  },

  computed: {},
};
</script>
