import { getInstance } from "./authWrapper";
import { getClaimName } from "./claims";
import auth0Config from "@/auth0Config";

export const eulaGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    const eula = getClaimName("acceptedEulaVersion");
    const eulaVersion = authService.user[eula];

    if (eulaVersion === auth0Config.eulaVersion) return next();

    return next({ name: "Eula", params: { redirectTo: to.path } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
