var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("group_work")]),_vm._v(" Org Unit "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New Org Unit ")],1)],1),_c('div',{staticClass:"ml-4 mb-4"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"prepend-icon":"mdi-magnify","label":_vm.searchLabel,"single-line":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":true,"must-sort":true,"no-data-text":'',"headers":_vm.headers,"items":_vm.orgunits,"loading":_vm.loading,"search":_vm.searchQuery},on:{"click:row":function (item) { return _vm.selectOrgUnit(item); }},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){return [_c('v-icon',[_vm._v("group_work")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.name, _vm.searchQuery))}})]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.code, _vm.searchQuery))}})]}},{key:"item.addressLineOne",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.addressLineOne, _vm.searchQuery))}})]}},{key:"item.addressLineTwo",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.addressLineTwo, _vm.searchQuery))}})]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.city, _vm.searchQuery))}})]}},{key:"item.zipCode",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.zipCode, _vm.searchQuery))}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.phone, _vm.searchQuery))}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }