<template>
  <v-menu transition="slide-y-transition" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" depressed>
        Actions <v-icon right>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="performAction(action)" v-for="(action, index) in actions" :key="index">
        <v-list-item-title>{{ action }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ActionDropdown",

  props: {
    performAction: Function,
    actions: Array,
  },
};
</script>
