export default {
  namespaced: true,

  state: {
    terminologies: undefined,
  },

  mutations: {
    mutateTerminologies: (state, terminologies) => (state.terminologies = terminologies),
  },
  actions: {
    updateTerminologies({ commit }, { terminologies }) {
      let terminologyHash = {};

      // Transform the list to a has for easier access
      terminologies.forEach(t => {
        terminologyHash[t.terminologyId] = t.customizedName || t.terminologyName;
      });

      commit("mutateTerminologies", terminologyHash);
    },
  },
};
