<template>
  <v-dialog v-model="show" persistent max-width="850" @click:outside="cancel">
    <v-card>
      <v-card-text class="title pt-4">Signature</v-card-text>
      <v-card-text class="text-center">
        <vue-drawing-canvas 
          ref="VueCanvasDrawing"
          :image.sync="imageAsBase64"
          :stroke-type="strokeType"
          :fill-shape="fillShape"
          :eraser="eraser"
          :lineWidth="line"
          :color="color"
          :background-color="backgroundColor"
          :background-image="backgroundImage"
          :watermark="watermark"
          saveAs="png"
          :styles="{
            border: 'solid 1px #bbb',
          }"
          :lock="disabled"
          :width="signatureSize.width"
          :height="signatureSize.height"
        />
        <v-row class="ma-1">
          {{ writtenNameAndDate }}
          <v-spacer />
          <v-btn @click.prevent="clear" text>Clear</v-btn>
          <v-btn class="mr-2" @click="cancel" text>Cancel</v-btn>
          <v-btn color="primary" @click="skipSignature" class="mx-2">Return unsigned</v-btn>
          <v-btn color="primary" :disabled="disableSignatureButton" @click="acceptClicked">Return</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueDrawingCanvas from "vue-drawing-canvas";
import dateService from "@/services/dateService";
import userSignatureImageService from "@/services/userSignatureImageService";
import { mapState } from "vuex";
import Auth0Mixin from "../views/Auth0Mixin";

const IMAGE_BASE_PREFIX = "data:image/png;base64,";
let preloadedSignature = "";

export default {
  name: "SignatureDialog",
  components: {
    VueDrawingCanvas,
  },
  mixins: [Auth0Mixin],
  props: {
    signatureSize: Object,
    close: Function,
    accept: Function,
    skipSignature: Function,
    show: Boolean,
  },
  watch: {
    show: function(newVal){
      if (newVal){
        this.backgroundImage = preloadedSignature;
        if (this.$refs.VueCanvasDrawing)
          this.$refs.VueCanvasDrawing.reset();
      }  
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      imageAsBase64: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 2,
      color: "#000000",
      strokeType: "dash",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
    };
  },

  computed: {
    ...mapState("auth0Store", ["user"]),
    ...mapState("userProfile", ["dateFormat"]),
    writtenNameAndDate() {
      if (!this.dateFormat || !this.user) return "";

      let formattedDate = dateService.formatDateTime(new Date(), this.dateFormat);
      return `${this.user.displayName} - ${formattedDate}`;
    },
    disableSignatureButton() {
      return this.imageAsBase64 !== "" && this.$refs.VueCanvasDrawing.isEmpty() && this.backgroundImage === "";
    },
  },
  
  methods: {
    async init() {
      preloadedSignature = await userSignatureImageService.getUserSignatureImage(this.user.id);
    },
    cancel() {
      this.$refs.VueCanvasDrawing.clear();
      this.close(false);
    },
    clear() {
      this.backgroundImage = "";
      this.$refs.VueCanvasDrawing.reset();
    },
    makeImageTransparent(image) {
      let canvas = document.createElement('canvas');
      canvas.width = this.signatureSize.width;
      canvas.height = this.signatureSize.height;

      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      let imageData = ctx.getImageData(0, 0, this.signatureSize.width, this.signatureSize.height);
      let pix = imageData.data;

      // Loops through all of the pixels
      for (let i = 0, n = pix.length; i <n; i += 4) {
        // Pixel is white
        if (pix[i] == 255 &&
            pix[i+1] == 255 &&
            pix[i+2] == 255)
        {
          // Set transparency to 0
          pix[i+3] = 0;
        }
      }

      ctx.putImageData(imageData, 0, 0);
      this.imageAsBase64 = canvas.toDataURL("image/png");
    },
    async persistSignature() {
      let signatureImage = {
        userId : this.user.id,
        signatureImageAsBase64 : this.imageAsBase64,
      };
      if (preloadedSignature === ""){
        await userSignatureImageService.saveUserSignatureImage(signatureImage);
      }else{
        await userSignatureImageService.updateUserSignatureImage(signatureImage);
      }
    },    
    async acceptClicked() {
      if (this.$refs.VueCanvasDrawing.isEmpty()){
        this.imageAsBase64 = this.backgroundImage;
        this.accept(this.imageAsBase64.substring(IMAGE_BASE_PREFIX.length));
      } else {
        let image = new Image();
        image.onload = () =>
        {
          this.makeImageTransparent(image);
          this.persistSignature();
          this.accept(this.imageAsBase64.substring(IMAGE_BASE_PREFIX.length));
        };
        image.src = this.imageAsBase64; 
      }
    },
  },
};
</script>
