<template>
  <div>
    <div class="docvue-background">
      <img src="/img/landing-page.jpg" />
    </div>
    <v-flex style="margin-top: 7rem">
      <v-card class="elevation-1 card mt-4 pa-4 card-form justify-center" style="max-width: 800px; margin: 500px auto">
        <div v-if="!company.invitationToken" class="text-center pt-4 mt-4 mb-4 pb-4">
          Already have an account? <a href="javascript:void(0)" @click="login()">Login instead</a>
        </div>
        <div v-if="company.invitationToken" class="text-center pt-4 mt-4 mb-4 pb-4 title already-login">
          <a href="javascript:void(0)" @click="gotoInvitation()">Already have a login?</a>
        </div>

        <v-form ref="form" v-model="isValid">
          <v-flex class="ma-8">
            <h3 class="display-1 mb-4 pb-4">Sign Up</h3>
            <h3 class="headline mb-4">Account Details</h3>
            <entity-form :entity="company" :inputFields="companyFields" />
            <h5 class="headline mb-3 mt-5">Administrative User</h5>
            <entity-form :entity="company" :inputFields="adminFields" />
          </v-flex>
        </v-form>
        <v-card-actions class="mr-4 mb-4">
          <v-spacer />
          <v-btn @click="save()" :disabled="!isValid" large color="primary" :loading="loading" block x-large depressed
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import ValidationRules from "@/validationRules";
import companyRegistrationService from "@/services/companyRegistrationService";
import States from "@/constants/states";
import { getInstance } from "../auth/authWrapper";
import EntityForm from "@/components/EntityForm";

let companyNameRule = v => {
  let stripNonAzureContainerChar = v ? v.replace(/[^a-z0-9]/i, "") : "";
  return (!!v && stripNonAzureContainerChar.length > 2) || "Legal Name must have at least 3 alphanumeric charachters";
};

let zipCodeRule = v => (!!v && v.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) || "Must be a valid US Zip Code";

const COMPANY_FIELDS = [
  { label: "Legal Name", attribute: "legalName", type: "text", rules: [ValidationRules.requiredRule, companyNameRule] },
  { label: "Address Line One", attribute: "addressLineOne", type: "text", rules: [ValidationRules.requiredRule] },
  { label: "Address Line Two", attribute: "addressLineTwo", type: "text" },
  { label: "City", attribute: "city", type: "text", rules: [ValidationRules.requiredRule] },
  { label: "State", attribute: "state", type: "select", items: States, rules: [ValidationRules.requiredRule] },
  {
    label: "Zip Code",
    attribute: "zipCode",
    type: "text",
    rules: [ValidationRules.requiredRule, zipCodeRule],
    maxlength: 10,
  },
  {
    label: "Tax Identification Number",
    attribute: "taxNumber",
    type: "text",
    rules: [ValidationRules.requiredRule],
  },
];

const ADMIN_FIELDS = [
  { label: "First Name", attribute: "userFirstName", type: "text", rules: [ValidationRules.requiredRule] },
  { label: "Last Name", attribute: "userLastName", type: "text", rules: [ValidationRules.requiredRule] },
  {
    label: "Email",
    attribute: "userEmail",
    type: "text",
    rules: [ValidationRules.requiredRule, ValidationRules.emailRule],
  },
];

export default {
  mixins: [ValidationRules],
  components: { EntityForm },
  name: "Register",
  props: {
    inviationToken: String,
    isBoarding: Boolean,
  },
  data() {
    return {
      loading: false,
      isValid: false,
      company: {
        invitationToken: this.inviationToken,
        isBoarding: this.isBoarding,
      },
      companyFields: COMPANY_FIELDS,
      adminFields: ADMIN_FIELDS,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
  },

  async created() {},

  methods: {
    login() {
      const authService = getInstance();
      authService.loginWithRedirect({ appState: { targetUrl: this.redirectTo || "/" } });
    },

    gotoInvitation() {
      const authService = getInstance();
      if (this.isBoarding)
        authService.loginWithRedirect({ appState: { targetUrl: `/admin/onboarding/${this.company.invitationToken}` } });
      else
        authService.loginWithRedirect({ appState: { targetUrl: `/admin/invitations/${this.company.invitationToken}` } });
    },

    async save() {
      this.loading = true;

      try {
        var company = await companyRegistrationService.registerCompany(this.company);
        this.$router.push({
          name: "RegisterSuccess",
          params: { companyName: company.name },
        });
      } catch (err) {
        this.loading = false;
        let isDuplicate = err.response.data && err.response.data.status === 409;
        if (isDuplicate) {
          this.$root.$snackbar.error(err.response.data.error.message);
        } else {
          this.$root.$snackbar.error(`The registeration didn't work. Please contact the admin.`);
        }
      }
    },
  },
};
</script>

<style scoped>
.already-login {
  background: #eee;
}
</style>
