<template>
  <vue-dropzone
    v-if="dropzoneOptions"
    ref="myVueDropzone"
    id="dropzone"
    :include-styling="false"
    :options="dropzoneOptions"
    @vdropzone-success="successSending"
    @vdropzone-sending="beforeSending"
    @vdropzone-error="error"
    @vdropzone-canceled="cancelled"
    :useCustomSlot="true"
  >
    <div>
      <span class="py-2">
        <v-icon left>mdi-upload</v-icon>
        <span>{{ label || "Click to upload" }}</span>
      </span>
    </div>
  </vue-dropzone>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { API_URL } from "../env";
import Auth0Mixin from "../views/Auth0Mixin";

import { mapGetters } from "vuex";
export default {
  mixins: [Auth0Mixin],
  props: {
    success: Function,
    onFinished: Function,
    onBeforeSending: Function,
    url: String,
    label: String,
    fileUploadData: Object,
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token"]),
  },

  data() {
    return {
      dropzoneOptions: undefined,
    };
  },

  methods: {
    async init() {
      this.dropzoneOptions = {
        url: this.url ? `${API_URL}${this.url}` : `${API_URL}/companyImage/upload`,
        addRemoveLinks: true,
        parallelUploads: 1,
        thumbnailWidth: 150,
        createImageThumbnails: false,
        maxFilesize: 5,
        maxFiles: 1,
        autoProcessQueue: true,
        accept: function(file, done) {
          if (file.name.length > 175) {
            done("The file name is too long.");
          } else {
            done();
          }
        },
        headers: {
          "Company-Id": this.selectedCompanyId,
          Authorization: `Bearer ${this.auth0Token}`,
        },
      };
    },

    beforeSending(file, xhr, formData) {
      if (this.fileUploadData) {
        formData.append("fileUploadData", JSON.stringify(this.fileUploadData));
      }

      if (this.onBeforeSending) this.onBeforeSending();

      return false;
    },

    error() {
      if (this.onFinished) this.onFinished();
    },

    cancelled() {
      if (this.onFinished) this.onFinished();
    },

    successSending(file, response) {
      if (this.success) {
        this.$refs.myVueDropzone.removeAllFiles();
        this.success(file, response);
      }
    },
  },
};
</script>
<style>
#dropzone .dz-preview .dz-remove,
.dz-error-message {
  display: none;
}
#dropzone .dz-success-mark,
.dz-error-mark {
  display: none;
}
</style>
