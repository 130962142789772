import partyType from "./partyType";
import terminology from "./terminology";

export default [
  { id: partyType.VENDOR, name: "Vendor", terminologyId: terminology.VENDOR },
  { id: partyType.OWNER, name: "Owner" },
  { id: partyType.OPERATOR, name: "Operator" },
  { id: partyType.BANK, name: "Bank" },
  { id: partyType.PURCHASER, name: "Purchaser" },
];
