export default {
  AFE: 1,
  COST_CENTER: 2,
  ACCOUNT_CODE: 3,
  VENDOR: 4,
  ORG_UNIT: 5,
  DECK: 6,
  WELL: 7,
  PROPERTY: 8,
};
