import userService from "../services/userService";
import userType from "../constants/userType";
import role from "@/constants/role";

const SELECTED_COMPANY_KEY = "dovcue.exchange.selectedCompanyId";

export default {
  namespaced: true,

  state: {
    auth0Token: undefined,
    auth0Initialized: false,
    user: { roles: [], companies: [] },
    selectedCompanyId: localStorage.getItem(SELECTED_COMPANY_KEY)
      ? parseInt(localStorage.getItem(SELECTED_COMPANY_KEY))
      : null,
  },

  getters: {
    user: state => {
      return { ...state.user };
    },
    userRoles: state => state.user.roles,
    companies: state =>
      [...state.user.companies].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    isOnBoardingPending: state => state.user.companies.find(c => c.id === state.selectedCompanyId)?.onBoardingProcessPending,
    isGlobalUser: state => state.user.userType.id === userType.Global,
    isAdmin: state => state.user.roles.includes(role.Administrator),
    auth0Token: state => state.auth0Token,
    selectedCompanyId: state => state.selectedCompanyId,
  },

  mutations: {
    mutateAuth0Token: (state, token) => (state.auth0Token = token),
    mutateAuth0Initialized: (state, auth0Initialized) => (state.auth0Initialized = auth0Initialized),
    mutateUser: (state, user) => (state.user = { ...user , companies: state.user.companies }),
    mutateSelectedCompanyId: (state, selectedCompanyId) => (state.selectedCompanyId = selectedCompanyId),
    updateProfile: (state, profile) => (state.user = { ...state.user, ...profile }),
    mutateCompnies: (state, companies) => state.user.companies.push(...companies),
  },
  actions: {
    updateCompanyId({ commit }, { selectedCompanyId }) {
      commit("mutateSelectedCompanyId", selectedCompanyId);
      localStorage.setItem(SELECTED_COMPANY_KEY, selectedCompanyId);
    },

    updateUser({ commit }, { user }) {
      let existingSelectedCompanyId = parseInt(localStorage.getItem(SELECTED_COMPANY_KEY));

      let selectedCompanyId = user.companies[0].id;

      // Make sure its a valid company ID that the user has access to
      if (existingSelectedCompanyId && user.companies.find(c => c.id === existingSelectedCompanyId)) {
        selectedCompanyId = existingSelectedCompanyId;
      }
      
      localStorage.setItem(SELECTED_COMPANY_KEY, selectedCompanyId);
      commit("mutateUser", user);
      commit("mutateSelectedCompanyId", selectedCompanyId);
    },

    updateAuth0Initialized({ commit }) {
      commit("mutateAuth0Initialized", true);
    },

    updateCompanies({ commit }, companies) {
      commit("mutateCompnies", companies);
    },

    async saveUserProfile({ commit }, profile) {
      const { firstName, lastName, displayName, emailAddress, title } = await userService.updateUser(profile);
      commit("updateProfile", { firstName, lastName, displayName, emailAddress, title });
    },

    updateAuth0Token({ commit }, { token }) {
      commit("mutateAuth0Token", token);
    },
  },
};
