export default {
  toQueryString: options => {
    let orderByDirection = options.sortDesc[0] ? "desc" : "asc";
    let itemsPerPage = !options.itemsPerPage || options.itemsPerPage === -1 ? 100000 : options.itemsPerPage;

    return `page=${options.page || 1}&itemsPerPage=${itemsPerPage}&orderBy=${
      options.sortBy
    }&orderByDirection=${orderByDirection}`;
  },
};
