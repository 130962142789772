import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth0Store", ["auth0Initialized"]),
  },

  watch: {
    async auth0Initialized() {
      this.init();
    },
  },

  created() {
    if (this.auth0Initialized) {
      this.init();
    }
  },
};
