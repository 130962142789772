<template>
  <v-card v-show="show" elevation="0">
    <v-card-text class="title pt-2">
      <v-icon left>mdi-auto-fix</v-icon>
      {{ rows.length }} Suggested {{ rows.length > 1 ? "Rows" : "Row" }}
    </v-card-text>

    <v-data-table
      fixed-header
      disable-pagination
      :hide-default-footer="true"
      :must-sort="true"
      :no-data-text="''"
      :headers="headers"
      :items="rows"
    >
      <template v-for="slot in headers" v-slot:[`item.${slot.value}`]="{ item }">
        <DocumentMetadata :isLineItem="true" :documentMetadata="item[slot.value]" :key="slot.value" />
      </template>

      <template v-slot:item.icon="{ item }">
        <v-checkbox v-model="item.selected"></v-checkbox>
      </template>
    </v-data-table>

    <v-card-text class="pt-4">
      <v-btn color="primary" @click="ok" :disabled="disableAddButton">
        <v-icon left>mdi-plus</v-icon>
        Add to detail items
      </v-btn>
      <v-btn color="grey" text @click="cancel">Cancel</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import DocumentMetadata from "@/components/DocumentMetadata";

export default {
  components: { DocumentMetadata },

  props: {
    rows: Array,
    headers: Array,
    show: Boolean,
    close: Function,
  },

  computed: {
    disableAddButton() {
      return this.rows.every(r => !r.selected);
    },
  },

  methods: {
    ok() {
      this.close(true);
    },

    cancel() {
      this.close(false);
    },
  },
};
</script>
