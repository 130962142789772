<template>
  <v-select
    :items="lookupValues"
    :disabled="documentMetadata.metadata.isReadonly"
    v-model="documentMetadata.value"
    hide-details
    ref="textField"
    item-text="lookupValue"
    item-value="id"
    :label="label"
    :data-cy="documentMetadata.metadata.key"
  ></v-select>
</template>

<script>
import metadataService from "@/services/metadataService";
import _ from "lodash";

export default {
  name: "ListMetadata",
  props: {
    documentMetadata: Object,
  },

  data() {
    return {
      lookupValues: [],
    };
  },
  computed: {
    label() {
      return this.isLineItem ? "" : this.documentMetadata.metadata.name;
    },
  },

  async created() {
    let values = await metadataService.getLookupValues(
      this.documentMetadata.metadata.id,
      this.documentMetadata.metadata.isThirdParty
    );
    this.documentMetadata.value = isNaN(parseInt(this.documentMetadata.value))
      ? null
      : parseInt(this.documentMetadata.value);
    this.lookupValues = _.sortBy(values, [
      function(o) {
        return o.lookupValue;
      },
    ]);
  },
};
</script>
