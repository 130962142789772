import httpService from "./httpService";

export default {
  getById: async (id, documentTypeId) =>
    httpService.get(
      `/documents/${id}?includeMetadata=true&documentTypeId=${documentTypeId ||
        -1}&includeRelatedDocuments=true&trackHistory=false&includeThirdPartyMetadata=true`
    ),

  getProposedLineItems: async (id, documentTypeId) =>
    httpService.get(`/documents/${id}/ProposedLineItems/${documentTypeId}/-1?includeThirdPartyMetadata=true`),

  publish: async document => httpService.put(`/documents/${document.id}/publish`, document),

  cancel: async id => httpService.post("/documents/cancelPublish", { ids: id }),

  getAutoExtract: async (documentId, documentTypeId) =>
    httpService.post("/autoextraction", {
      documentId,
      documentTypeId,
      documentMetadata: [],
    }),

  getVersions: async id => httpService.get(`/documents/${id}/versions`),

  getAttachments: async id => httpService.get(`/documentAttachments/document/${id}`),
  deleteTemporaryDocument: async id => httpService.deleteRequest(`/documents/${id}/deleteTempFile`),
  resubmit: async (id, document, newVersion) =>
    httpService.post(`/documents/${id}/resubmit${newVersion ? `?newVersion=${newVersion}` : ""}`, document),

  markRead: async id => httpService.put(`/documents/${id}/markread`),
  idDocumentAvailable: async id => httpService.get(`/documents/${id}/available`),
};
