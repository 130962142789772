<template>
  <v-container fluid class="notification-page">
    <div class="notification-root d-flex flex-column">
      <v-card-title class="pa-5 flex-grow-0">Notifications</v-card-title>
      <div class="flex-grow-1 notification-content">
        <v-card
          v-for="notification in notifications"
          :key="notification.id"
          class="mt-2 mb-2 notification-card"
          @click="handleClick(notification)"
        >
          <v-card-title>
            <v-row>
              <v-col>{{ notification.title }}</v-col>
              <v-col class="text-right new-flag">{{ notification.isRead ? "Read" : "Not Read" }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            {{ notification.message }} <br />
            <span class="float-right"> {{ notification.updateDate }} </span>
            <br />
          </v-card-text>
        </v-card>
        <h3 v-if="notifications.length == 0">There are currently no notifications</h3>
      </div>
      <div class="flex-grow-0">
        <v-pagination :length="pages" @input="queryPage" v-model="currentPage"></v-pagination>
      </div>
    </div>
  </v-container>
</template>

<script>
import Auth0Mixin from "./Auth0Mixin";
import notificationService from "@/services/notificationService";
import dateService from "@/services/dateService";
import { mapState } from "vuex";
import notificationActions from "@/constants/notificationActions";
import documentService from "@/services/documentService";

let actionToRoute = notification => {
  let actionToRouteLookup = {
    [notificationActions.OPEN_DOCUMENT]: {
      route: "Document",
      routeData: { id: notification.actionData.documentId }
    },
    [notificationActions.OPEN_INVITATION]: {
      route: "InvitationList",
      routeData: { idByNotification: notification.actionData.InvitationId },
    },
  };
  return actionToRouteLookup[notification.actionId];
};

export default {
  mixins: [Auth0Mixin],
  computed: {
    ...mapState("userProfile", ["dateFormat"]),
  },
  data() {
    return {
      notifications: [],
      pages: 1,
      currentPage: 1,
    };
  },
  methods: {
    async init() {
      await this.queryPage();
    },
    async queryPage() {
      let notificationResult = await notificationService.getNotifications(this.currentPage, 5);
      this.$store.dispatch("userProfile/setUnreadNotification", notificationResult.unreadTotalRecords);
      this.notifications = notificationResult.items;
      this.pages = Math.ceil(notificationResult.totalRecords / notificationResult.pageSize);
      this.notifications.forEach (x => x.updateDate = dateService.formatDateTime(x.updateDate, this.dateFormat))
    },
    async handleClick(notification) {
      let handlers = {
        [notificationActions.OPEN_DOCUMENT]: async notification => {
          var isAvailable = await documentService.idDocumentAvailable(notification.actionData.documentId);
          if (isAvailable) {
            let route = actionToRoute(notification);
            this.$router.push({ name: route.route, params: route.routeData });
          } else {
            this.$root.$snackbar.message("The document is no longer available");
          }
        },
        [notificationActions.OPEN_INVITATION]: notification => {
          let route = actionToRoute(notification);
          this.$router.push({ name: route.route, params: route.routeData });
        },
        [notificationActions.UPLOAD_ERROR]: () => {},
      };
      handlers[notification.actionId](notification);
    },
  },
};
</script>

<style scoped>
.notification-page {
  height: 100%;
}
.notification-root {
  box-sizing: border-box;
  margin: 10px 5px;
  height: 100%;
}

.notification-content {
  overflow-y: scroll;
}

.notification-card {
  cursor: pointer;
}

.new-flag {
  font-size: medium;
  font-weight: 400;
}

.withBorder {
  border: 1px black solid;
}
</style>
