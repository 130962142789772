import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Eula from "../views/Eula.vue";
import Document from "../views/Document.vue";
import UserList from "../views/admin/UserList.vue";
import Register from "../views/Register.vue";
import RegisterSuccess from "../views/RegisterSuccess.vue";
import Settings from "../views/admin/Settings.vue";
import OrgUnitList from "../views/admin/OrgUnitList.vue";
import OrgUnit from "../views/admin/OrgUnit.vue";
import AddressSetList from "../views/admin/AddressSetList.vue";
import AddressSet from "../views/admin/AddressSet.vue";
import User from "../views/admin/User.vue";
import multiguard from "vue-router-multiguard";
import { authGuard, eulaGuard } from "../auth";
import NotFound from "../views/NotFound.vue";
import LandingPage from "../views/LandingPage.vue";
import PartyList from "../views/admin/PartyList.vue";
import PartyListImport from "../views/admin/PartyListImport.vue";
import Party from "../views/admin/Party.vue";
import CompanyLogo from "../views/admin/CompanyLogo.vue";
import InvitationList from "../views/admin/InvitationList.vue";
import Invitation from "../views/admin/Invitation";
import RegisterInvitation from "../views/admin/RegisterInvitation.vue";
import OnBoarding from "../views/admin/OnBoarding.vue";
import LegalDocumentList from "../views/admin/LegalDocumentList";
import LegalDocument from "../views/admin/LegalDocument";
import Inbox from "../views/Inbox";
import DocumentList from "../views/DocumentList";
import Profile from "../views/Profile";
import SearchResult from "../views/SearchResult";
import ErrorLoading from "../views/ErrorLoading";
import Notifications from "../views/Notification";
import AchList from "../views/admin/AchList.vue";
import Ach from "../views/admin/Ach.vue";

Vue.use(VueRouter);
const eulaAndAuthGuard = multiguard([authGuard, eulaGuard]);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/eula/:redirectTo",
    name: "Eula",
    component: Eula,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/documents/:id",
    name: "Document",
    component: Document,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/users",
    name: "UserList",
    component: UserList,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/user/:id",
    name: "User",
    component: User,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/settings",
    redirect: { name: "Settings.LegalDocumentList" },
    name: "Settings",
    component: Settings,
    beforeEnter: eulaAndAuthGuard,
    children: [
      {
        path: "companyLogo",
        name: "Settings.CompanyLogo",
        component: CompanyLogo,
      },
      {
        path: "legalDocuments",
        name: "Settings.LegalDocumentList",
        component: LegalDocumentList,
      },
      {
        path: "ach",
        name: "Settings.AchList",
        component: AchList,
      },
    ],
  },
  {
    path: "/admin/invitations",
    name: "InvitationList",
    component: InvitationList,
    beforeEnter: eulaAndAuthGuard,
    props: route => ({ idByNotification: route.params.idByNotification }),
  },
  {
    path: "/admin/invitation/:invitationId",
    name: "Invitation",
    component: Invitation,
    beforeEnter: eulaAndAuthGuard,
    props: route => ({ invitationId: route.params.invitationId, invitation: route.params.invitation }),
  },
  {
    path: "/admin/invitations/:invitationToken",
    name: "RegisterInvitation",
    component: RegisterInvitation,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/onboarding/:invitationToken",
    name: "OnBoarding",
    component: OnBoarding,
    beforeEnter: eulaAndAuthGuard,
    props: true,
  },
  {
    path: "/admin/settings/legalDocument/:id",
    name: "LegalDocument",
    component: LegalDocument,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/settings/ach/:id",
    name: "Ach",
    component: Ach,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/orgunits",
    name: "OrgUnitList",
    component: OrgUnitList,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/orgunit/:id",
    name: "OrgUnit",
    component: OrgUnit,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/address",
    name: "AddressSetList",
    component: AddressSetList,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/address/:id",
    name: "AddressSet",
    component: AddressSet,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/parties",
    name: "PartyList",
    component: PartyList,

    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/parties/import",
    name: "PartyListImport",
    component: PartyListImport,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/admin/party/:id",
    name: "Party",
    component: Party,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/:invitationToken",
    name: "RegisterByInvitation",
    component: Register,
    props: route => ({ inviationToken: route.params.invitationToken, isBoarding: false }),
  },
  {
    path: "/onboarding/:invitationToken",
    name: "BoardingCompany",
    component: Register,
    props: route => ({ inviationToken: route.params.invitationToken, isBoarding: true }),
  },
  {
    path: "/registersuccess",
    props: true,
    name: "RegisterSuccess",
    component: RegisterSuccess,
  },
  {
    path: "/landingpage",
    name: "LandingPage",
    component: LandingPage,
    props: route => ({ redirectTo: route.query.redirectTo }),
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/landingpage",
  },
  {
    path: "/errorLoading",
    name: "ErrorLoading",
    component: ErrorLoading,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/profile/unsubscribe",
    name: "UnsubscribeEmail",
    component: Profile,
    beforeEnter: eulaAndAuthGuard,
    props: { unsubscribeEmail: true },
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: Inbox,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/documentlist/:documentTypeId",
    name: "DocumentList",
    component: DocumentList,
    props: route => ({
      documentTypeId: Number(route.params.documentTypeId),
      routeStatusId: Number(route.query.documentRouteStatusId || 0),
    }),
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/search",
    name: "SearchResult",
    component: SearchResult,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: eulaAndAuthGuard,
  },
  {
    path: "*",
    component: NotFound,
    beforeEnter: eulaAndAuthGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // This is ugly, will try to find a better way (its to get the splitpanels to work) / Jonas
  global.document.getElementsByTagName("html")[0].style.overflow = "auto";
  next();
});

export default router;
