import httpService from "../httpService";

export default {
  getList: async (mustBeActive, page, pageSize, ids) =>
    httpService.get(
      `/companyorgunit?${mustBeActive ? "isActive=true&" : ""}page=${page || 1}&pageSize=${pageSize || 10000}${ids ? `&ids=${ids}` : ""}`
    ),
  get: async id => httpService.get(`/companyorgunit/${id}`),
  create: async orgUnit => httpService.post("/companyorgunit", orgUnit),
  update: async orgUnit => httpService.put(`/companyorgunit/${orgUnit.id}`, orgUnit),
  delete: async id => httpService.deleteRequest(`/companyorgunit/${id}`),
};
